import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Typography, TextField, makeStyles, IconButton, FormHelperText} from '@material-ui/core';
import CreateSharpIcon from "@material-ui/icons/CreateSharp";
import {useSelector} from "react-redux";
import AccessControl, {check} from "../../RBAC/AccessControl";
import rules from "../../../rbac-rules";
import permissions from "../../../permissions";

const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(1)
    }
}));

function PharmaCompany(props) {

    const [isEditing, setEditing] = useState(false);
    const { brand, updateBrand, saveBrand } = props;
    const [pharmaCompany, setPharmaCompany] = useState(brand.pharmaCompany)
    const classes = useStyles();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.update.basic]);

    const submit = () => {
        if (pharmaCompany !== '' && pharmaCompany !== null) {
            setEditing(false);
            if (pharmaCompany !== brand.pharmaCompany) {
                const updatedBrand = { ...brand, pharmaCompany };
                saveBrand({ brand: updatedBrand, msg: 'Pharma company updated' });
                updateBrand(updatedBrand);
            }
        }
    };

    const handleChange = (ev) => {
        setPharmaCompany(ev.target.value);
    };

    const onBlur = (ev) => {
        submit();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                {brand?.retailersEnabled ? 'Retail' : "Pharma" } Company
            </Typography>

            {isEditing ? (
                <>
                    <TextField
                        inputProps={{ 'aria-label': 'Pharma Company' }}
                        autoFocus
                        fullWidth
                        value={pharmaCompany === null ? '' : pharmaCompany}
                        name='pharmaCompany'
                        onBlur={onBlur}
                        onChange={handleChange}
                        size='small'
                        onKeyDown={handleKeyDown}
                        error={pharmaCompany === '' || pharmaCompany === null}
                    />
                    {pharmaCompany === '' &&
                        <FormHelperText error={pharmaCompany === ''}>This field is required</FormHelperText>
                    }
                </>
            ) : (
                <Typography
                    {...(canEdit &&
                        {onClick: () => setEditing(true)}
                    )}
                >
                    {pharmaCompany || ''}
                    <AccessControl allowedPermissions={[permissions.brands.update.basic]}>
                        <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft} onClick={() => setEditing(true)}>
                            <CreateSharpIcon fontSize='small' color='secondary' />
                        </IconButton>
                    </AccessControl>
                </Typography>
            )}
        </>
    );
}

PharmaCompany.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default PharmaCompany;
