import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Divider, Grid, makeStyles } from '@material-ui/core';
import AlertEmails from './AlertEmails';
import DomainUrls from './DomainUrls';
import BrandName from './BrandName';
import Description from './Description';
import routes from 'routes';
import roles from 'roles';

import ExclusionTerms from './ExclusionTerms'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme.palette.primary.dark
    }
}));

function ComplianceDetailsComponent(props) {

    const location = useLocation();
    const classes = useStyles();
    const { compliance, brands, updateCompliance, saveCompliance } = props;

    let params = new URLSearchParams();
    params.append('complianceID',compliance.id);
    params.append('role', roles.SEARCH_STRATEGIST.name);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                    <BrandName compliance={compliance} classes={classes} brands={brands} updateCompliance={updateCompliance} saveCompliance={saveCompliance} />
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader title='Compliance Details' />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <DomainUrls classes={classes} compliance={compliance} updateCompliance={updateCompliance} saveCompliance={saveCompliance} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <ExclusionTerms classes={classes} compliance={compliance} updateCompliance={updateCompliance} saveCompliance={saveCompliance} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <AlertEmails classes={classes} compliance={compliance} updateCompliance={updateCompliance} saveCompliance={saveCompliance} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <Description classes={classes} compliance={compliance} updateCompliance={updateCompliance} saveCompliance={saveCompliance} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </div>
    );
}

ComplianceDetailsComponent.propTypes = {
    brand: PropTypes.object,
    searchTerms: PropTypes.object,
    saveBrand: PropTypes.func,
    saveTerms: PropTypes.func,
    updateBrand: PropTypes.func,
    agencies: PropTypes.array
};

export default ComplianceDetailsComponent;
