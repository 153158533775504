import React, { useState, useEffect } from "react";
import axios from "axios";
import routes from "routes";
import ComplianceReprot from "./ComplianceReport";
import ComplianceTable from "./ComplianceTable";
import { Container } from '@material-ui/core';
import { toast } from "react-toastify";
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
{/**This component is used to logical combine auto report, edit report, and list of records   */ }
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        marginLeft: '15%'
    },
}));
function DirectComplianceReprot(props) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [terms, setTerms] = useState([]);
    const [description, setDescription] = useState([]);
    const [urls, setUrls] = useState([]);
    const [brand, setBrand] = useState(null);
    const [complianceReport, setComplianceReport] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.reports.endpoint}/getSearchTerms/${brand}`);
                setDescription(result.data);
                let newTerms = [];
                // let newDescription = [];
                result.data.forEach(element => {
                    newTerms = [...newTerms, ...element.terms.split(",")];
                    // newDescription = [...newDescription, {"complianceId": element.id, "description": element.description}];
                });
                setTerms(newTerms);
                // setDescription(newDescription);
            } catch (error) {
                console.log(error);
            }

        };
        if (brand) {
            fetchData();
        }


    }, [brand])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.reports.endpoint}/getUrls/${brand}`);
                let newUrls = [];
                result.data.forEach(element => {
                    newUrls = [...newUrls, ...element.urls.split(",")];
                });
                setUrls(newUrls);
    
            } catch (error) {
                console.log(error);
            }

        };
        if (brand) {
            fetchData();
        }


    }, [brand])

    const handleComplianceReport = async (report) => {
        setOpen(true);
        await axios({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: `${routes.reports.endpoint}/scrapecompliance`,
            data: JSON.stringify(report)
        }).then((response) => {
            setOpen(false);
            if (response.data.length > 0) {
                notify('Records are found!');
            } else {
                notify('Records are not found!');
            }
            setComplianceReport(response.data);
        }).catch(error => {
            setOpen(false);
            notify('Oops something went wrong!');
            console.log("error", error);
        })

    }
    const notify = (msg) => toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return <Container>
        <ComplianceReprot
            terms={terms}
            urls={urls}
            description={description}
            setBrand={setBrand}
            handleComplianceReport={handleComplianceReport}
            {...props} />
        <ComplianceTable
            complianceReport={complianceReport}
        />
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
}

export default DirectComplianceReprot;
