import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, Select } from '@material-ui/core';


function ReportSelect(props) {

    return (
        <>
            <InputLabel id='report-select-label'>Select Report</InputLabel>
            <Select
                labelId='report-select-label'
                id='report-select'
                value={props.report}

                onChange={event => props.handleReportSelect(event.target.value)}
                size='small'
            >
                <MenuItem value='brandcompliance'>Brand Non-Compliance Report</MenuItem>
                <MenuItem value='brandNonCompliance'>Brand Non-Compliance Automation</MenuItem>
                <MenuItem value='dailyBrandNonCompliance'>Daily Brand Non-Compliance Automation</MenuItem>
                <MenuItem value='weeklyFixedCompliance'>Weekly Automation Report</MenuItem>
                <MenuItem value='directCompliance'>Direct Placement Compliance Report</MenuItem>

            </Select>
        </>
    );
}

ReportSelect.propTypes = {
    report: PropTypes.string,
    setReport: PropTypes.func,
};

export default ReportSelect;
