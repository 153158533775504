import React, {useState,forwardRef} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import MaterialTable, {MTableBodyRow, MTableCell} from 'material-table';
import {ArrowUpward as ArrowUpwardIcon, FilterList as FilterListIcon} from '@material-ui/icons';
import palette from '../../../theme/palette';
import { TablePagination } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    tableRow: {
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableCell: {
        fontWeight: 'inherit'
    }
}));

function MaterialUITable({ data, columns, rowStyle, ...rest }) {


    const classes = useStyles();
    const height = window.innerHeight - 300;

    return (
        <MaterialTable
            key={data.length}
            options={{
                search: false,
                filtering: true,
                sorting: true,
                pagination: true,
                    // pageSize: rowsPerPage,
        //    pageSizeOptions: [5, 10, 100, { value: data.length, label: 'All' }],
                maxBodyHeight: height,
                toolbar: false,
                headerStyle: {
                    color: palette.primary.dark,
                    fontWeight: '900',
                },
                emptyRowsWhenPaging: false,
                pageSize: data.length,
                actionsColumnIndex: -1,
                rowStyle: rowStyle
            }}
            style={{borderRadius: 0, boxShadow: 'none'}}
            components={{
                Pagination: props => (<></>),
                Row: props => (<MTableBodyRow {...props} className={classes.tableRow}/>),
                Cell: props => (<MTableCell {...props} className={classes.tableCell}/>)
            }}
            data={data}
            icons={{
                Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref}/>),
                SortArrow: forwardRef((props, ref) => <ArrowUpwardIcon {...props} ref={ref}/>),
            }}
            columns={columns}
            localization={{
                header: {
                    actions: ''
                }
            }}
            {...rest}
        />
    );
}

MaterialUITable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowStyle: PropTypes.func
};

export default MaterialUITable;



function MaterialUITable1({ data, columns, rowStyle, ...rest }) {


    const classes = useStyles();
    const height = window.innerHeight - 300;
    // const [rowsPerPage, setRowsPerPage] = useState(3);
    // const handleChangeRowsPerPage = event => {
   
    //     //enable to set rows per page to match total count
       
    //     return  setRowsPerPage(event.target.value);
    //   };


    return (
        <MaterialTable
            key={data.length}
            options={{
                search: false,
                filtering: true,
                sorting: true,
                pagination: false,
                maxBodyHeight: height,
                pageSizeOptions: [250, 500, 700, { value: data.length, label: 'All' }],
                // pageSize:10,
        // rowsPerPageOptions:[3],
                toolbar: false,
                 pageSize: 250,
                headerStyle: {
                    color: palette.primary.dark,
                    fontWeight: '900',
                },
                // emptyRowsWhenPaging: false,
                
                actionsColumnIndex: -1,
                rowStyle: rowStyle
            }}
            style={{borderRadius: 0, boxShadow: 'none'}}
        //     components={{
        //  Pagination: props => (<></>),
        //         Row: props => (<MTableBodyRow {...props} className={classes.tableRow}/>),
        //         Cell: props => (<MTableCell {...props} className={classes.tableCell}/>)
        //     }}
            data={data}
            icons={{
                Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref}/>),
                SortArrow: forwardRef((props, ref) => <ArrowUpwardIcon {...props} ref={ref}/>),
            }}
            columns={columns}
            localization={{
                header: {
                    actions: ''
                }
            }}
            {...rest}
        />
    );
}

MaterialUITable1.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowStyle: PropTypes.func
};

export  {MaterialUITable1};