import React from 'react';
import Main from 'components/Layout/Main';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useCompliances from 'api/useCompliances';
import CompliancesTableComponent from 'components/compliances/List';

function CompliancesContainer() {

    const { isLoading, compliances } = useCompliances({}, [], true);

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.compliances.list]} noAccess={() => <Forbidden/>}>
                {isLoading ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <CompliancesTableComponent compliances={compliances}/>
                )}
            </AccessControl>
        </Main>
    );
}

export default CompliancesContainer;