import React from 'react';
import { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import routes from 'routes';

function BrandSelect(props) {

    const { brand, setBrand, userBrands, setUserBrands, strategist } = props;
    const [filteredBrands, setFilteredBrands] = useState([]);

    const handleBrandChange = (event, newValue, reason) => {
        setBrand(newValue.filter(option => option.id !== -1))
       
    };

    const handleBrandDelete = (event, item) => {
        setBrand(brand.filter(option => option.id !== item.id));

    };
    console.log(strategist,"brand s")
    console.log(brand,"brand")

    useEffect(() => {
        const fetchData = async (userIds) => {
            let temp = [];
            try {
                const result = await axios.post(`${routes.users.endpoint}/userBrand`, userIds);
                console.log("userBrands>", result.data.data)
                console.log("Brands", props.brands)
                result.data.data.forEach(elem => {
                    temp.push(...props.brands.filter(item => elem.brand_id == item.id))
                });
                setFilteredBrands(temp.length ? temp : []);
            } catch (error) {
                console.log(error);
            }

        };
        let userIds = [];
        
        strategist.forEach(element => { userIds.push(element.id) });
        if (userIds.length > 0) {
            fetchData(userIds);
        }

    }, [strategist]);

    useEffect(()=>{
        if(brand.length == 0 &&  strategist.length == 0){
            setFilteredBrands(props.brands)
        }
    },[brand,strategist])

    console.log(filteredBrands, "filteredBrands")

    // props.brands.filter((value)=>value.searchStrategists[0].id === strategist)
    return (
        <>
            <Autocomplete
                multiple
                id='brand-select'

                options={filteredBrands}
                //options={filteredBrands}



                getOptionLabel={(option) => option.brandName}

                autoHighlight
                getOptionSelected={
                    (option, brand) => {
                        return option.id === brand.id
                    }
                }
                filterSelectedOptions
                value={brand.length === 0 ? [{ id: -1, brandName: 'All', value: '' }] : brand}
                onChange={
                    (event, newValue, reason) => handleBrandChange(event, newValue, reason)
                }

                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            {...(option.id !== -1 && getTagProps({ index }))}
                            key={option.id}
                            label={option.brandName}
                            size='small'
                            {...(option.id !== -1 && { onDelete: ((event) => handleBrandDelete(event, option)) })}
                        />
                    )
                    )}
                renderInput={params => (
                    <TextField {...params} label='Select Brand' />
                )}
            />
        </>
    );
}

BrandSelect.propTypes = {
    brand: PropTypes.array,
    setBrand: PropTypes.func,
    setLastSelectedBrand: PropTypes.func,
    setLastDeletedBrand: PropTypes.func
};

export default BrandSelect;