import React from 'react';
import MaterialTable from 'material-table'
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    marginLeft: '15%'
  },
}));


function ComplianceTable({ complianceReport }) {
  const classes = useStyles();
  let columns = [
    { title: 'Brand Name', field: 'brandName' },
    // { title: 'Detail', field: 'description' },
    { title: 'Search Terms', field: 'terms' },
    { title: 'Status', field: 'scrapStatus' },
    { title: 'Main Urls', field: 'urls' },
    { title: 'Child Urls', field: 'oneLayerDownUrls' },
    { title: 'Title', field: 'title', cellStyle: { display: 'block', height: '100px', overflow: 'auto' } },

  ]

  return (
    <>

      <MaterialTable
        title="DIRECT PLACEMENT LIST"
        columns={columns}
        data={complianceReport}
        options={{
          exportButton: true,
          actionsCellStyle: {
            wwidth: '370px',
            padding: '0px 40px'
          },
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#3fb44f',
            color: '#FFF'
          },
          rowStyle: (rowData, index) => {
            return index % 2 === 0 ? {
              backgroundColor: '#fcfcfc',
              border: '2px solid #d6d6f5',
              wordBreak: 'break-all',
            } : {
              backgroundColor: '#faf0f6',
              border: '2px solid #d6d6f5',
              wordBreak: 'break-all',
            }
          }
        }}
      />
    </>
  )
}

export default ComplianceTable;
