import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import axios from 'axios';
import { Button, Card, CardContent, CardHeader, Divider, Grid, makeStyles } from '@material-ui/core';
import { toast } from "react-toastify"
import AccessControl, { check } from '../../RBAC/AccessControl';
import rules from '../../../rbac-rules';
import { useSelector } from 'react-redux';
import permissions from '../../../permissions';
import ComplianceTable from './ComplianceTable/ComplianceTable';
import ScheduleTableColumn from './ComplianceTable/ScheduleTableColumn'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    addButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        marginLeft: '15%'
    },
}));

export default function CompliancesTableComponent(props) {

    const classes = useStyles();
    let history = useHistory();
    const [open, setOpen] = useState(false);
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.compliances.detail]);
    const { compliances } = props;
    let data = [];
    for (let i = 0; i < compliances.length; i++) {
        let temp = compliances[i].createdAt.split('T');
        let date = temp[0];
        let time = temp[1].split('.')[0];

        let urlsList = compliances[i].urls.split(",").map(item => `\n${item}`)
        let urlsTerms = compliances[i].terms.split(",").map(item => `\n${item}`)

        data.push({
            ...compliances[i],
            'urls': urlsList.join(","),
            'terms': urlsTerms.join(","),
            'createdAt': `${date}  ${time}`,
        })
    }

    const deleteCompliance = async ({ id }) => {
        
        setOpen(true);
        try {
            const result = await axios.get(`${routes.scheduleCompliance.endpoint}/complianceParamDeleteById/${id}`);
            if (result.data == 'success') {
                setOpen(false);
                notify('Compliance Deleted Successfully!');
                history.push(`/`)
                history.push(`${routes.compliances.path}`)
            }
        } catch (error) {
            setOpen(false);
            console.log(error);
            notifyError('Error Occurred While Deleting The Compliance!');
        }
    }
    const scrapCompliance = async ({ id }) => {
        
        setOpen(true);
        try {
            const result = await axios.get(`${routes.scrapCompliance.endpoint}/getComplianceExtratorbyid/${id}`);
            if (result.data == 'success') {
                setOpen(false);
                notify('Compliance Scrapped Successfully!');
            }else{
                setOpen(false);
                notifyError('Oops Something went wrong with the website domain url');
            }
        } catch (error) {
            setOpen(false);
            console.log(error);
            notifyError('Error Occurred While Scrapping The Compliance!');
        }
    }
    const notify = (msg) => toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const notifyError = (msg) => toast.warning(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card variant='outlined'>
                        <CardHeader
                            title={routes.compliances.pageTitle}
                            subheader='The following compliances have been configured for your agency. You can add additional compliances or edit compliances from this screen.'
                            action={
                                <AccessControl allowedPermissions={[permissions.compliances.create]}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.addButton}
                                        onClick={
                                            () => history.push(`${routes.compliances.path}/new`)
                                        }
                                    >
                                        Add Compliance
                                    </Button>
                                </AccessControl>
                            }
                        />
                        <Divider />
                        <CardContent className={classes.cardContent}>
                            <ComplianceTable
                                data={data}
                                deleteCompliance={deleteCompliance}
                                scrapCompliance={scrapCompliance}
                                columns={[
                                    { title: 'Brand Name', field: 'brandName' },
                                    { title: 'Details', field: 'description' },
                                    { title: 'Alert Emails', field: 'alertEmails' },
                                    { title: 'Urls', field: 'urls' },
                                    { title: 'Terms', field: 'terms' },
                                    { title: 'Created Date', field: 'createdAt' },
                                    { title: 'Schedule', 
                                    field: 'id', 
                                    disableClick: true,
                                    filtering: false,
                                    render: rowData =><ScheduleTableColumn rowData={rowData} {...props} />  },
                                ]}
                                {...(canEdit && {
                                    onRowClick:
                                        ((event, rowData) => {
                                            let complianceId = rowData.id;
                                            history.push(`${routes.compliances.path}/${complianceId}`);
                                        })

                                })}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}