import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { Typography, TextField, makeStyles, Chip, IconButton, FormHelperText } from '@material-ui/core';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useSelector} from 'react-redux';
import AccessControl, {check} from 'components/RBAC/AccessControl';
import rules from 'rbac-rules';
import permissions from 'permissions';

const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        overflowX: 'hidden',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    }
}));

function ExclusionTerms(props) {

    const [isEditing, setEditing] = useState(false);
    const [readyToSave, setReadyToSave] = useState(false);
    const [firstTimeClick, setFirstTimeClick] = useState(true);
    const [newTerm, setNewTerm] = useState('');
    const [lastCreatedBySpace, setLastCreatedBySpace] = useState(false);
    const { compliance, updateCompliance, saveCompliance } = props;
    const [complianceTerms, setComplianceTerms] = useState(compliance.terms === null ? [] :compliance.terms.split(","));

    const classes = useStyles();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.compliances.update.full]);

    const submit = () => {
        if (complianceTerms.length > 0) {
            setEditing(false);
            setReadyToSave(false);
            setFirstTimeClick(true);
            let terms = compliance.terms.split(",");
            if (complianceTerms !== terms) {
                const updatedCompliance = { ...compliance, "terms":complianceTerms.toString() };
                saveCompliance({ compliance: updatedCompliance, msg: 'Terms updated!' });
                updateCompliance(updatedCompliance);
       
            }
        }
    };

    const onBlur = () => {
        submit(compliance);
    };

    const handleOnKeyDown = (event) => {
       
         if (event.key === 'Enter' && readyToSave) {
            submit(compliance);
        } else if (event.key === 'Enter' && firstTimeClick) {
            submit(compliance);
        } else if (event.key === 'Backspace' && lastCreatedBySpace && newTerm === '') {
            let terms = complianceTerms;
            terms.pop();
            handleComplianceTermsChange(event, terms, '');
        }
    };

    const handleComplianceTermsChange = (event, newValue, reason) => {
        setComplianceTerms(newValue.filter(value => value.trim() !== ''));
        setLastCreatedBySpace(false);
    };

    const handleComplianceTermsDelete = (event, term) => {
        setReadyToSave(true);
        setComplianceTerms(complianceTerms.filter(option => option !== term));
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Blocklist Terms
            </Typography>

            {isEditing ? (
                <Autocomplete
                    multiple
                    freeSolo
                    id='brand-domain-urls'
                    options={[]}
                    onKeyDown={handleOnKeyDown}
                    value={complianceTerms}
                    onChange={(event, newValue, reason) =>
                        handleComplianceTermsChange(event, newValue, reason)}
                    onInputChange={(event, value, reason) => {
                        setFirstTimeClick(false);
                        setNewTerm(value);
                        setReadyToSave( value.trim() === '');
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                                <Chip
                                    {...getTagProps({index})}
                                    label={option}
                                    onDelete={event => handleComplianceTermsDelete(event, option)}
                                    size='small'
                                />
                            )
                        )}
                    renderInput={params => (
                            <>
                                <TextField {...params} inputProps={{...params.inputProps, value: newTerm}} 
                                onBlur={onBlur}
                                size='small'
                                // error={complianceTerms.length > 5} 
                                autoFocus 
                                />
                             
                                {complianceTerms.length === 0 &&
                                   <FormHelperText error={complianceTerms.length === 0}>This field is required</FormHelperText>
                                }
                            </>)}
                />
            ) : (
                <div
                    className={classes.chipRoot}
                    {...(canEdit &&
                        {onClick: () => setEditing(true)}
                    )}
                >
                    {complianceTerms && complianceTerms.map((domain, idx) => (
                        <Chip key={idx} label={domain} size='small' />
                    ))}
                    <AccessControl allowedPermissions={[permissions.compliances.update.full]}>
                        <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft} onClick={() => setEditing(true)}>
                            <CreateSharpIcon fontSize='small' color='secondary' />
                        </IconButton>
                    </AccessControl>
                </div>
            )}
        </>
    );
}

ExclusionTerms.propTypes = {
    classes: PropTypes.object,
    compliance: PropTypes.object,
    updateCompliance: PropTypes.func,
    saveCompliance: PropTypes.func
};

export default ExclusionTerms;
