import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, Divider, Grid, makeStyles } from '@material-ui/core';
import routes from 'routes';
import roles from 'roles';

import permissions from 'permissions';
import AccessControl, {check} from 'components/RBAC/AccessControl';
import {useSelector} from 'react-redux';
import rules from '../../../rbac-rules';
import MaterialUITable from '../../Custom/MaterialUITable';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    addButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

function UsersTableComponent(props) {

    const classes = useStyles();
    let history = useHistory();
    const { users } = props;
    const { currentUser } = useSelector(state => state.users);

    // const currentUser = {
    //     "name": null,
    //     "id": null,
    //     "active": true,
    //     "role": "AGENCY_MANAGER",
    //     "tempPassword": null,
    //     "email": "aravindad@chetu.com",
    //     "agency": null,
    //     "agencyId": 1,
    //     "phone": null
    // }

    const canEdit = check(rules, currentUser.role, [permissions.users.update]);
    const [validUsers, setValidUsers] = useState([]);
    
    console.log("currentUser>>", currentUser)
    console.log("users>>", users)
    console.log("roles>>", roles)


    useEffect(()=>{
        if(currentUser.role === roles.SYSTEM_ADMINISTRATOR.name){
            setValidUsers(users);
        }
        if(currentUser.role === roles.SEARCH_STRATEGIST.name){
            setValidUsers( users.filter((item)=>item.role === currentUser.role &&item.role === roles.SEARCH_STRATEGIST.name));
        }
        if(currentUser.role === roles.AGENCY_MANAGER.name){
            setValidUsers( users.filter((item)=> item.agencyId === currentUser.agencyId ));
            //setValidUsers( users.filter((item)=> item.agencyId === currentUser.agencyId && item.role === 'SEARCH_STRATEGIST'));
        }
    }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card variant='outlined'>
                        <CardHeader
                            title={routes.users.pageTitle}
                            subheader='The following users have been configured for your agency. You can add additional
                            users or edit users from this screen.'
                            action={
                                <AccessControl allowedPermissions={[permissions.users.create]}>
                                    <Button variant='contained' color='secondary' className={classes.addButton}
                                        onClick={() => history.push(`${routes.users.path}/new`)}
                                    >
                                        Add User
                                    </Button>
                                </AccessControl>
                            }
                        />
                        <Divider/>
                        <CardContent className={classes.cardContent}>
                            <MaterialUITable
                                data={validUsers}
                                columns={[
                                    {title: 'Name', field: 'name'},
                                    {title: 'Email', field: 'email'},
                                    {
                                        title: 'Agency', field: 'agency',
                                        render: rowData => rowData.agency === null ? '' : rowData.agency.name,
                                        customFilterAndSearch: (term, rowData) => rowData.agency === null ? false : (rowData.agency.name.toLowerCase()).includes(term.toLowerCase())
                                    }, {
                                        title: 'Role', field: 'role',
                                        render: rowData => rowData.role !== undefined ? roles[rowData.role].displayName : '',
                                        customFilterAndSearch: (term, rowData) =>
                                            rowData.role === null ? false : (roles[rowData.role].displayName.toLowerCase()).includes(term.toLowerCase())
                                    }, {
                                        title: 'Status',
                                        field: 'active',
                                        render: rowData => (
                                            rowData.active ? 'Active' : 'Inactive'
                                        ),
                                        lookup: { true: 'Active', false: 'Inactive' }
                                    },
                                ]}
                                {...(canEdit &&
                                    {onRowClick: ((event, rowData) => {
                                            let userId = rowData.id;
                                            history.push(`${routes.users.path}/${userId}`);
                                        })}
                                )}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

UsersTableComponent.propTypes = {
    users: PropTypes.array
};

export default UsersTableComponent;
