import React from "react";
import { Button, Modal, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

{ {/**This component is used to view the schedule query which is executed in backend */ } }
function PreviewScheduledQuery(props) {

    const handleTextCopy = (text) => {
        navigator.clipboard.writeText(text);
    }

    return <>

        <Modal
            open={props.openSchedule}
            onClose={props.handleCloseSchedule}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            
        >
            <Box style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                backgroundColor:'white',
                boxShadow: 24,
               
                overflow:'auto',
                height:'70%',
            }}>
                <Box style={{
                    padding:'20px',
                    backgroundColor:'green',
                     }} 
                     >
                    <Typography id="modal-modal-title" variant="h3" component="h2" align="center" style={{ color: "white" }}>
                        Schedule Report
                    </Typography>
                </Box>
                <Box>
                    {props.previewData.length > 0 ?
                        props.previewData.map((value) => {
                            return <Box sx={{ p: 2 }}>
                                <Box>
                                    {value}
                                </Box>
                                <Box>
                                    <Button onClick={(event) => handleTextCopy(value)} variant="contained" style={{ backgroundColor: 'grey', color: 'white' }}>
                                        Copy
                                    </Button>
                                </Box>
                            </Box>
                        }) : 'Loading...'
                    }
                </Box>
                <Box sx={{ p: 1 }}>
                    <Button onClick={(event) => props.handleCloseSchedule()} variant="contained" style={{ backgroundColor: 'grey', color: 'white', float: 'right', margin: '10px' }}>
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    </>
}
export default PreviewScheduledQuery;