import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Modal, Typography, TextField, Grid, Paper, styled, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import Box from '@material-ui/core/Box';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  alignItems: "center",
  justifyContent: "space-around"
}));

{/** This component is used to edit the schedule report. */ }
const EditScheduleCompliance = (props) => {

  let history = useHistory();
  const [btnStatus, setBtnStatus] = useState(false);
  const [autoReportId, setAutoReportId] = useState(props.editSchedule.autoComplianceId);
  const [id, setId] = useState(props.editSchedule.id);


  const [startDate, setStartDate] = useState(props.editSchedule.startDate);

  const [reportType, setReportType] = useState(props.editSchedule.complianceReportType);
  const [day, setDay] = useState(props.editSchedule.scheduleDay ? props.editSchedule.scheduleDay : "Sunday");
  const [time, setTime] = useState(props.editSchedule.scheduleTime);


  const [isStartDateEmpty, setIsStartDateEmpty] = useState(false);
  const [isTimeEmpty, setIsTimeEmpty] = useState(false);
  const [isReportTypeEmpty, setIsReportTypeEmpty] = useState(false);
  const [isDayEmpty, setIsDayEmpty] = useState(false);

  const saveSchedule = async () => {
    setBtnStatus(true)
    const schedule = {
      id: id,
      autoComplianceId: autoReportId,
      startDate: startDate,
      complianceReportType: reportType,
      scheduleTime: time,
      scheduleDay: day
    }

    if (reportType == 'Daily') {
      schedule.scheduleDay = null
    }

    if (startDate != '' && reportType != '' && reportType != null && day != '' && day != null && time != '') {

      await axios({
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: `${routes.scheduleCompliance.endpoint}/updateComplianceScheduleData`,
        data: JSON.stringify(schedule)
      }).then((response) => {

        if (response.status == 200) {
          notify();
          setStartDate('');
          setReportType('Daily');
          setDay('Sunday');
          setTime('');
          setBtnStatus(false);
          props.setEditScheduleId(null);
          props.handleClose();
          history.push(`/`)
          history.push(`${routes.compliances.path}`)

        }

      }).catch(error => {
        console.log(error);
        setBtnStatus(false);
      })
    } else {
      if (startDate == '') { setIsStartDateEmpty(true) }
      if (reportType == '' || reportType == null) { setIsReportTypeEmpty(true) }
      if (day == '' || day == null) { setIsDayEmpty(true) }
      if (time == '') { setIsTimeEmpty(true) }
      setBtnStatus(false);
      return;
    }

  }
  const notify = () => toast.success('Successfully Saved', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return (
    <>

      <Modal
        open={props.open}
        onClose={() => { props.handleClose(); setReportType('Daily'); }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          backgroundColor: 'white',
          boxShadow: 24,
          borderRadius: 5,
        }}>
          <Box style={{
            padding: '20px',
            backgroundColor: 'green',
          }}
          >
            <Typography id="modal-modal-title" variant="h3" component="h2" align="center" style={{ color: "white" }}>
              Schedule Compliance
            </Typography>
          </Box>
          <Box style={{
            padding: '25px'
          }}
          >
            <Grid container spacing={4}  >
              <Grid item xs={6}>
                <Typography id="modal-modal-title" variant="h6" >Start Date*</Typography>
                <input type="date" className="customform" value={startDate} onChange={(e) => setStartDate(e.target.value)} style={isStartDateEmpty ? { borderColor: "red" } : {}} />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  defaultValue={reportType}
                  id="tags-standard"
                  options={['Daily', 'Weekly']}

                  onChange={(event, value) => { setReportType(value); setIsReportTypeEmpty(false) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Report Type*"
                      placeholder="Select"
                      error={isReportTypeEmpty}
                    />
                  )}
                />
              </Grid>
              {reportType == 'Weekly' && <Grid item xs={6}>
                <Autocomplete

                  defaultValue={day}
                  id="tags-standard"
                  options={['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'saturday']}

                  onChange={(event, value) => { setDay(value); setIsDayEmpty(false) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Day*"
                      placeholder="Select"
                      error={isDayEmpty}
                    />
                  )}
                />
              </Grid>}
              <Grid item xs={6}>
                <Typography id="modal-modal-title" variant="h6" >Time*</Typography>
                <input type="time" className="customform" value={time} label="Time*" onChange={(e) => setTime(e.target.value)} style={isTimeEmpty ? { borderColor: "red" } : {}} />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ p: 4 }}>
            <Button onClick={(event) => props.handleClose()} variant="contained" style={{ backgroundColor: 'grey', color: 'white', float: 'right', margin: '10px' }}>
              Close
            </Button>
            <Button
              disabled={btnStatus}
              onClick={saveSchedule}
              variant="contained"
              style={{ backgroundColor: 'green', color: 'white', float: 'right', margin: '10px' }}>
              Schedule
            </Button>
          </Box>
        </Box>
      </Modal>

    </>
  )
}
export default EditScheduleCompliance;
