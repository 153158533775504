import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios';
import routes from 'routes';
import permissions from 'permissions';
import rules from 'rbac-rules';
import {check} from 'components/RBAC/AccessControl';
import {setBrands as setPropsBrands, setCompliances as setPropsCompliances} from 'actions';

export default (params, dependencies, condition) => {
    const [compliances, setCompliances] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {role} = useSelector(state => state.users.currentUser);
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.compliances.endpoint}/getComplianeData`, {params});
                setCompliances(result.data);
                dispatch(setPropsCompliances(result.data));
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (check(rules, role, [permissions.compliances.list]) && condition) {
            setIsLoading(true);
            fetchData();
        }
    }, dependencies);

    return {isLoading, compliances};
}
