import React, { useState, useEffect } from "react";
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import { Button, Container, Typography, TextField, Grid, Paper, styled } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import MultipleValueTextInput from 'react-multivalue-text-input';

{/** This component is used to edit the records of table. */ }
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
    inputRoot: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
            color: "red"
        },
    },
    repcont: {
        textAlign: 'center',
    },
    repbtn: {
        float: 'left',

    },
    reptitle: {
        display: 'inline-block',
        marginTop: '10px'
    }
}));
function EditableDailyAutomationReport(props) {
    const classes = useStyles();
    const [btnStatus, setBtnStatus] = useState(false);
    const [reportName, setReportName] = useState(props.editableDailyReportData.reportname);
    const [adHostList, setAdHostList] = useState(props.editableDailyReportData.adhostTemp.split(','));
    const [isReportNameEmpty, setIsReportNameEmpty] = useState(false);
    const [isAdHostListEmpty, setIsAdHostListEmpty] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleReportSubmit = async () => {
        setBtnStatus(true);
        const report = {
            "reportType": props.report,
            "adhostTemp": adHostList,
            "createdAt": props.editableDailyReportData.createdAt,
            "id": props.editableDailyReportData.id,
            "reportname": reportName,
            "agencyname": null,
            "agencyid": null,
            "brandid": null,
            "adprovider": [],
            "adhost": [],

            "adbrandedurl": [],
            "brandedUrlStatus": null,
            "searchTerm": null,
            "hijackAdHostList": [],
            "brandedUrlList": [],
            "brandedUrlValue": null
        }

        if ((reportName != '' && adHostList.length > 0)) {
            await axios({
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                url: `${routes.scheduleReport.endpoint}/saveQueryParam`,
                data: JSON.stringify(report)
            }).then((response) => {
                if (response.status == 200) {
                    notify();
                    setReportName('');
                    setAdHostList([]);
                    setBtnStatus(false);
                    props.triggerRefresh();
                    props.resetDailyBrandEditId();

                }

            })
                .catch(error => {
                    console.log(error);
                    setBtnStatus(false);
                })
        } else {
            if (reportName == '') { setIsReportNameEmpty(true) }

            if (adHostList.length == 0) { setIsAdHostListEmpty(true) }
            setBtnStatus(false);
            return;
        }

    }
    const notify = () => toast.success('Successfully Saved', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return <>
        <Container className={classes.repcont} >
            <Typography variant="h3" gutterBottom component="div" className={classes.reptitle} >
                WELCOME TO AUTOMATION REPORT SYSTEM
            </Typography>
        </Container>
        <Grid container spacing={4}>
            <Grid item xs={4}>
                <TextField
                    value={reportName}
                    id="standard-basic"
                    label="Report Name*"
                    variant="standard"
                    fullWidth="true"
                    error={isReportNameEmpty}
                    onChange={(event) => { setReportName(event.target.value); setIsReportNameEmpty(false) }}
                />
            </Grid>

            <Grid item xs={4} className="multivaluecustominput" >
                <MultipleValueTextInput
                    values={adHostList}
                    onItemAdded={(item, allItems) => { setAdHostList([...adHostList, item]); setIsAdHostListEmpty(false) }}
                    onItemDeleted={(item, allItems) => setAdHostList(adHostList.filter((value) => value != item))}

                    label="AdHost List *"
                    name="item-input"
                    placeholder="Ad Host List."
                    style={isAdHostListEmpty ? { borderColor: "red" } : null}
                />

            </Grid>
            <Grid item xs={4} className="text-right1" >
                <Button
                    disabled={btnStatus}
                    onClick={handleReportSubmit}
                    variant="contained"
                    color='secondary'
                    type='submit'
                >Submit</Button>
                <Button
                    onClick={() => props.resetDailyBrandEditId()}
                    variant="contained"
                    type='submit'
                    style={{ marginLeft: "20px" }}
                >Cancel</Button>
            </Grid>
        </Grid>
    </>
}

export default EditableDailyAutomationReport;