import React, { useState, useEffect } from 'react';
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import MaterialTable from 'material-table'
import dateConverter from 'utils/dateConverter';
import WeeklyScheduleTableColumn from './WeeklyScheduleTableColumn'
import PreviewWeeklyScheduledQuery from './PreviewWeeklyScheduledQuery';


function WeeklyScheduleListTable(props) {
  const [previewId, setPreviewId] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [openSchedule, setOpenSchedule] = useState(false);
  const handleOpenSchedule = () => setOpenSchedule(true);
  const handleCloseSchedule = () => {
    setOpenSchedule(false);
    setPreviewId(null);
  }
  let data = [];
  for (let i = 0; i < props.weeklyReportData.length; i++) {
    data.push({
      scheduleId: props.weeklyReportData[i][0],
      reportname: props.weeklyReportData[i][13],
      agencyname: props.weeklyReportData[i][6],
      createddate: dateConverter(props.weeklyReportData[i][11]),
    })
  }
  let columns = [
    { title: 'Report Name', field: 'reportname' },
    { title: 'Agency Name', field: 'agencyname' },
    {
      title: 'Schedule',
      field: 'scheduleId',
      render: rowData => <WeeklyScheduleTableColumn rowData={rowData} {...props} />
    },
    { title: 'Created Date', field: 'createddate' },

  ]

  useEffect(() => {

    const fetchData = async () => {
      try {
        const result = await axios.get(`${routes.scheduleReport.endpoint}/showQueryById/${previewId}`);
        if (result.status == "200") {
          setPreviewData(result.data);
        }


      } catch (error) {
        console.log(error);
      }

    };
    if (previewId != null) {
      fetchData();
    }
  }, [previewId])

  const previewSchedule = (rowData) => {
    setPreviewData('')
    setPreviewId(rowData.scheduleId);
    handleOpenSchedule();
  }

  const deleteReport = (rowData) => {
    const deleteReport = async () => {
      try {

        const result = await axios.get(`${routes.scheduleReport.endpoint}/QueryParamDeleteById/${rowData.scheduleId}`);
        if (result.data === "success") {
          notify();
          props.triggerRefresh();
        }


      } catch (error) {
        console.log(error);
      }

    };
    deleteReport();
  }
  const notify = () => toast.warning('Successfully Deleted', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return (
    <>

      <MaterialTable
        title="SCHEDULE LIST"
        columns={columns}
        data={data}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit Report',
            onClick: (event, rowData) => props.handleEditReport(rowData)
          },
          {

            icon: 'delete',
            tooltip: 'Delete Report',
            onClick: (event, rowData) => deleteReport(rowData)
          },
          {

            icon: 'preview',
            tooltip: 'View Query',
            onClick: (event, rowData) => previewSchedule(rowData)
          }
        ]}
        options={{
          actionsCellStyle: {
            wwidth: '370px',
            padding: '0px 40px'
          },
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#3fb44f',
            color: '#FFF'
          },
          rowStyle: (rowData, index) => {
            return index % 2 === 0 ? {
              backgroundColor: '#fcfcfc',
              border: '2px solid #d6d6f5',
              wordBreak: 'break-all',
            } : {
              backgroundColor: '#faf0f6',
              border: '2px solid #d6d6f5',
              wordBreak: 'break-all',
            }
          }
        }}
      />
      <PreviewWeeklyScheduledQuery
        openSchedule={openSchedule}
        handleCloseSchedule={handleCloseSchedule}
        previewData={previewData}
      />
    </>
  )
}

export default WeeklyScheduleListTable;
