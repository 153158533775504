import React, { useState } from "react";
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import { Button, IconButton, TextField, Grid, Paper, styled, Container, Typography } from '@material-ui/core';
import MultipleValueTextInput from 'react-multivalue-text-input';
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
    repcont: {
        textAlign: 'center',
    },
    repbtn: {
        float: 'left',
    },
    reptitle: {
        display: 'inline-block',
        marginTop: '10px'
    }

}));
{/** This component is used to daily brand records */ }
function DailyAutomationReport(props) {
    const classes = useStyles();
    const [btnStatus, setBtnStatus] = useState(false);
    const [reportName, setReportName] = useState('');

    const [adHostList, setAdHostList] = useState([]);

    const [isReportNameEmpty, setIsReportNameEmpty] = useState(false);

    const [isAdHostListEmpty, setIsAdHostListEmpty] = useState(false);
    const [autoclear, setAutoclear] = useState(true);

    const handleReportSubmit = async () => {
        setBtnStatus(true);
        const report = {
            "reportType": props.report,
            "adhostTemp": adHostList,
            "createdAt": "",
            "id": "",
            "reportname": reportName,
            "agencyname": null,
            "agencyid": null,
            "brandid": null,
            "adprovider": [],
            "adhost": [],

            "adbrandedurl": [],
            "brandedUrlStatus": null,
            "searchTerm": null,
            "hijackAdHostList": [],
            "brandedUrlList": [],
            "brandedUrlValue": null
        }

        if ((reportName != '' && adHostList.length > 0)) {
            await axios({
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                url: `${routes.scheduleReport.endpoint}/saveQueryParam`,
                data: JSON.stringify(report)
            }).then((response) => {
                if (response.status == 200) {
                    notify();
                    setReportName('');

                    setAdHostList([]);
                    setBtnStatus(false);
                    setAutoclear(!autoclear)
                    props.triggerRefresh();

                }

            })
                .catch(error => {
                    console.log(error);
                    setBtnStatus(false);
                })
        } else {
            if (reportName == '') { setIsReportNameEmpty(true) }

            if (adHostList.length == 0) { setIsAdHostListEmpty(true) }
            setBtnStatus(false);
            return;
        }
    }
    const notify = () => toast.success('Successfully Saved', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return <>
        <div>
            <Container className={classes.repcont} >
                <IconButton aria-label='Go Back' edge='start' onClick={() => props.handleReportSelect('brandcompliance')} className={classes.repbtn}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant="h3" gutterBottom component="div" className={classes.reptitle} >
                    WELCOME TO DAILY BRAND NON-COMPLIANCE AUTOMATION REPORT
                </Typography>
            </Container>
        </div>
        <Grid container spacing={2} className="mb-2">
            <Grid item xs={4}>
                <TextField
                    value={reportName}
                    id="standard-basic"
                    label="Report Name*"
                    variant="standard"
                    fullWidth="true"
                    error={isReportNameEmpty}
                    onChange={(event) => { setReportName(event.target.value); setIsReportNameEmpty(false) }}
                />
            </Grid>

            <Grid item xs={4} className="multivaluecustominput">
                <MultipleValueTextInput
                    key={autoclear}
                    onItemAdded={(item, allItems) => { setAdHostList([...adHostList, item]); setIsAdHostListEmpty(false) }}
                    onItemDeleted={(item, allItems) => setAdHostList(adHostList.filter((value) => value != item))}
                    label="AdHost List *"
                    name="item-input"
                    placeholder="Ad Host List."
                    style={isAdHostListEmpty ? { borderColor: "red" } : null}
                />

            </Grid>

            <Grid item xs={4} className="text-right1">
                <Button
                    disabled={btnStatus}
                    onClick={handleReportSubmit}
                    variant="contained"
                    color='secondary'
                    type='submit'
                >Submit</Button>
            </Grid>
        </Grid>
    </>
}

export default DailyAutomationReport;