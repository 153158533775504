import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Chip, IconButton, makeStyles, TextField, Typography, FormHelperText} from '@material-ui/core';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useSelector} from "react-redux";
import AccessControl, {check} from "../../RBAC/AccessControl";
import rules from "../../../rbac-rules";
import permissions from "../../../permissions";
import validateEmail from 'utils/validateEmail';
const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        overflowX: 'hidden',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    }
}));

function AlertEmails(props) {

    const [isEditing, setEditing] = useState(false);
    const [readyToSave, setReadyToSave] = useState(false);
    const [firstTimeClick, setFirstTimeClick] = useState(true);
    const [lastCreatedBySpace, setLastCreatedBySpace] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const {compliance, updateCompliance, saveCompliance} = props;

    const [complianceAlertEmails, setComplianceAlertEmails] = useState(compliance.alertEmails === null ? [] : compliance.alertEmails.split(","));
    const classes = useStyles();
    const {currentUser} = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.compliances.update.basic]);

    const submit = () => {
        if(complianceAlertEmails.length > 0){
            setEditing(false);
            setReadyToSave(false);
            setFirstTimeClick(true);
            let alertEmails = compliance.alertEmails.split(",");
            if (complianceAlertEmails !== alertEmails) {
                const updatedCompliance = {...compliance, "alertEmails":complianceAlertEmails.toString()};
                saveCompliance({compliance: updatedCompliance, msg: 'Email alerts updated!'});
                updateCompliance(updatedCompliance);
             
            }
        }
       
    };

    const onBlur = () => {
        submit();
    };

    const handleOnKeyDown = (event) => {
        if (event.key === ' ' && newEmail !== '' && newEmail !== ' ') {
            let emails = complianceAlertEmails;
            if (!emails.includes(newEmail)) {
                if(validateEmail([newEmail])){
                    emails.push(newEmail);

                }
            }
            handleComplianceAlertEmailsChange(event, emails, '');
            setNewEmail('');
            setReadyToSave(true);
            setLastCreatedBySpace(true);
        } else if (event.key === 'Enter' && readyToSave) {
            submit();
        } else if (event.key === 'Enter' && firstTimeClick) {
            submit();
        } else if (event.key === 'Backspace' && lastCreatedBySpace && newEmail === '') {
            let emails = complianceAlertEmails;
            emails.pop();
            handleComplianceAlertEmailsChange(event, emails, '');
        }
    };

    const handleComplianceAlertEmailsChange = (event, newValue, reason) => {
        if (validateEmail(newValue)) {
            setComplianceAlertEmails(newValue.filter(value => value.trim() !== ''));
            setLastCreatedBySpace(false);

        }
    };

    const handleComplianceAlertEmailsDelete = (event, email) => {
        setReadyToSave(true);
        setComplianceAlertEmails(complianceAlertEmails.filter(option => option !== email));
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Email Alerts
            </Typography>

            {isEditing ? (
                <Autocomplete
                    multiple
                    freeSolo
                    id='brand-email-alerts'
                    options={[]}
                    onKeyDown={handleOnKeyDown}
                    value={complianceAlertEmails}
                    onChange={(event, newValue, reason) =>
                        handleComplianceAlertEmailsChange(event, newValue, reason)}
                    onInputChange={(event, value, reason) => {
                        setFirstTimeClick(false);
                        setNewEmail(value.trim());
                        setReadyToSave(value.trim() === '');
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                                <Chip
                                    {...getTagProps({index})}
                                    label={option}
                                    onDelete={event => handleComplianceAlertEmailsDelete(event, option)}
                                    size='small'
                                />
                            )
                        )}
                    renderInput={params => (
                        <>
                            <TextField {...params} inputProps={{...params.inputProps, value: newEmail}} onBlur={onBlur}
                                       size='small' autoFocus/>

                              {complianceAlertEmails.length === 0 &&
                                <FormHelperText error={complianceAlertEmails.length === 0}>This field is required</FormHelperText>
                            }
                        </>)}
                />
            ) : (
                <div
                    className={classes.chipRoot}
                    {...(canEdit &&
                        {onClick: () => setEditing(true)}
                    )}
                >
                    {complianceAlertEmails.map((email, idx) => (
                        <Chip key={idx} label={email} size='small'/>
                    ))}
                    <AccessControl allowedPermissions={[permissions.brands.update.basic]}>
                        <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft}
                                    onClick={() => setEditing(true)}>
                            <CreateSharpIcon fontSize='small' color='secondary'/>
                        </IconButton>
                    </AccessControl>
                </div>
            )}
        </>
    );
}

AlertEmails.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default AlertEmails;
