import React, { useState, useEffect } from "react";
import axios from "axios";
import routes from "routes";
import DailyAutomationReport from "./DailyAutomationReport";
import EditableAutomationReportSystem from "./EditableDailyAutomationReport";
import DailyBrandScheduleListTable from "./DailyBrandScheduleListTable";
import { Container } from '@material-ui/core';

{/** This component is used to synchronise/connect the brand report, table, and to edit the brands records */ }
function DailyBrandNonComplianceReport(props) {
    const [dailyBrandEditId, setDailyBrandEditId] = useState(null);
    const [editableDailyReportData, setEditableDailyReportData] = useState('');

    useEffect(() => {

        const fetchData = async () => {

            try {

                const result = await axios.get(`${routes.scheduleReport.endpoint}/getReportDataWithID/${dailyBrandEditId}`);


                setEditableDailyReportData(result.data);


            } catch (error) {
                console.log(error);
            }
        };
        if (dailyBrandEditId != null) {
            fetchData();
        }

    }, [dailyBrandEditId])

    const editDailyReport = (rowData) => {

        setDailyBrandEditId(rowData.scheduleId);


    }
    const resetDailyBrandEditId = () => {
        setEditableDailyReportData('');
        setDailyBrandEditId(null);
    }

    return <Container>

        {dailyBrandEditId != null && editableDailyReportData != '' ? <EditableAutomationReportSystem
            {...props}
            editableDailyReportData={editableDailyReportData}
            resetDailyBrandEditId={resetDailyBrandEditId}
        />
            :
            <DailyAutomationReport {...props} />
        }
        <DailyBrandScheduleListTable
            {...props}
            editDailyReport={editDailyReport}
        />

    </Container>
}

export default DailyBrandNonComplianceReport;