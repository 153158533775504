import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import roles from "../../../roles";

function SearchStrategistSelect(props) {
    const { currentUser } = useSelector(state => state.users);
    const { strategist, setStrategist, users } = props;
    const [validUsers, setValidUsers] = useState([]);

    useEffect(() => {
        if(currentUser.role === roles.SYSTEM_ADMINISTRATOR.name){
            setValidUsers(users.filter((item) => item.role === roles.SEARCH_STRATEGIST.name))
        }
        
        if(currentUser.role === roles.SEARCH_STRATEGIST.name || currentUser.role === roles.AGENCY_MANAGER.name){
            setValidUsers(users.filter((item) => item.agencyId === currentUser.agencyId && item.role === roles.SEARCH_STRATEGIST.name))
        }
    }, [])

    const handleStrategistChange = (event, newValue, reason) => {
        setStrategist(newValue.filter(option => option.id !== -1));
    };
    console.log(strategist, "strategist")
    const handleSearchStrategistDelete = (event, item) => {
        setStrategist(strategist.filter(option => option.id !== item.id));
    };

    return (
        <>
            <Autocomplete
                multiple
                id='strategist-select'
                // options={props.users}
                options={validUsers}
                getOptionLabel={(option) => option.name}
                autoHighlight
                getOptionSelected={
                    (option, searchStrategist) => {
                        return option.id === searchStrategist.id
                    }
                }
                filterSelectedOptions
                value={strategist.length === 0 ? [{ id: -1, name: 'All' }] : strategist}
                onChange={
                    (event, newValue, reason) => handleStrategistChange(event, newValue, reason)
                }
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            {...(option.id !== -1 && getTagProps({ index }))}
                            key={option.id}
                            label={option.name}
                            size='small'
                            {...(option.id !== -1 && { onDelete: ((event) => handleSearchStrategistDelete(event, option)) })}
                        />)
                    )
                }
                renderInput={params => (
                    <TextField {...params} label='Search Strategist ss' />
                )}
            />
        </>
    );
}

SearchStrategistSelect.propTypes = {
    strategist: PropTypes.array,
    setStrategist: PropTypes.func,
};

export default SearchStrategistSelect;