import React, { useState, useEffect } from "react";
import { Button, IconButton, Checkbox, TextField, Grid, Paper, styled, Typography, Container } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

{/**This component is to create auto report */ }
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
    repcont: {
        textAlign: 'center',
    },
    repbtn: {
        float: 'left',

    },
    reptitle: {
        display: 'inline-block',
        marginTop: '10px'
    }

}));

function AutomationReprotSystem(props) {

    const classes = useStyles();
    const [btnStatus, setBtnStatus] = useState(false);
    const [reportName, setReportName] = useState('');
    const [agencyName, setAgencyName] = useState('');
    const [agencyId, setAgencyId] = useState('');
    const [brandName, setBrandName] = useState('');
    const [searchEngine, setSearchEngine] = useState([]);
    const [Advertiser, setAdvertiser] = useState([]);
    const [brandedUrl, setBrandedUrl] = useState([]);
    const [brandId, setBrandId] = useState('');
    const [brandNameList, setBrandNameList] = useState([]);
    const [isReportNameEmpty, setIsReportNameEmpty] = useState(false);
    const [isAgencyNameEmpty, setIsAgencyNameEmpty] = useState(false);
    const [isBrandNameEmpty, setIsBrandNameEmpty] = useState(false);
    const [autoclear, setAutoclear] = useState(true);

    const agencyNameOptions = [];
    const brandNameOptions = [];
    const searchEngineOptions = [{ title: 'GOOGLE', value: 'GOOGLE' }, { title: 'BING', value: 'BING' }];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getBrandList/${brandId}`);

                setBrandNameList(result.data);

            } catch (error) {
                console.log(error);
            }

        };
        fetchData();

    }, [brandId])



    for (let i = 0; i < props.agencyName.length; i++) {
        agencyNameOptions.push({ id: props.agencyName[i][0], agency: props.agencyName[i][1] })
    }
    for (let i = 0; i < brandNameList.length; i++) {
        brandNameOptions.push({ id: brandNameList[i][0], brandName: brandNameList[i][1] })
    }

    const handleAgencyName = (event, value, reason, details) => {

        setIsAgencyNameEmpty(false);
        if (value !== null) {
            setAgencyName(value.agency);
            setAgencyId(value.id);
            setBrandId(value.id)

        } else {
            setAgencyName('');
            setAgencyId('');
            setBrandId('')

        }

    }
    const handleBrandName = (event, value, reason, details) => {
        setIsBrandNameEmpty(false)
        if (value !== null) {
            setBrandName(value.id);

        } else {
            setBrandName('');
        }

    }
    const handleSearchEngine = (event, value, reason, details) => {

        let arr = [];
        if (value.length > 0) {

            for (let i = 0; i < value.length; i++) {
                arr.push(value[i].value)
            }
            setSearchEngine(arr);
        } else {
            setSearchEngine([])
        }

    }

    const handleReportSubmit = async (event) => {
        setBtnStatus(true);
        const report = {
            "reportType": props.report,
            "adhostTemp": [],
            "createdAt": "",
            "id": "",
            "reportname": reportName,
            "agencyname": agencyName,
            "agencyid": agencyId,
            "brandid": brandName,
            "adprovider": searchEngine,
            "adhost": Advertiser,
            "adbrandedurl": brandedUrl,
            "brandedUrlStatus": null,
            "searchTerm": null,
            "hijackAdHostList": [],
            "brandedUrlList": [],
            "brandedUrlValue": null
        }

        if ((reportName != '' && agencyName != '' && brandName != '')) {
            await axios({
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                url: `${routes.scheduleReport.endpoint}/saveQueryParam`,
                data: JSON.stringify(report)
            }).then((response) => {
                if (response.status == 200) {
                    notify();
                    setReportName('');
                    setAgencyName('');
                    setBrandName('');
                    setSearchEngine([]);
                    setAdvertiser([]);
                    setBrandedUrl([]);
                    setBtnStatus(false);
                    setAutoclear(!autoclear);
                    props.triggerRefresh();

                }

            })
                .catch(error => {
                    console.log(error);
                    setBtnStatus(false);
                })
        } else {
            if (reportName == '') { setIsReportNameEmpty(true) }
            if (agencyName == '') { setIsAgencyNameEmpty(true) }
            if (brandName == '') { setIsBrandNameEmpty(true) }
            setBtnStatus(false);
            return;
        }
    }
    const notify = () => toast.success('Successfully Saved', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return <>
        <div>
            <Container className={classes.repcont} >
                <IconButton aria-label='Go Back' edge='start' onClick={() => props.handleReportSelect('brandcompliance')} className={classes.repbtn}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant="h3" gutterBottom component="div" className={classes.reptitle} >
                    WELCOME TO BRAND NON-COMPLIANCE AUTOMATION REPORT
                </Typography>
            </Container>
            <Grid container spacing={2} className="mb-2">
                <Grid item xs={4}>
                    <TextField
                        value={reportName}
                        id="standard-basic"
                        label="Report Name*"
                        variant="standard"
                        fullWidth="true"
                        error={isReportNameEmpty}
                        onChange={(event) => { setReportName(event.target.value); setIsReportNameEmpty(false) }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete

                        id="tags-standard"
                        options={agencyNameOptions}
                        getOptionLabel={(option) => option.agency}
                        key={autoclear}
                        onChange={handleAgencyName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Agency Name*"
                                placeholder="Select"
                                error={isAgencyNameEmpty}
                            />
                        )}
                    />

                </Grid>
                <Grid item xs={4}>
                    <Autocomplete

                        id="tags-standard"
                        options={brandNameOptions}
                        getOptionLabel={(option) => option.brandName}
                        key={autoclear}
                        onChange={handleBrandName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Brand Name*"
                                placeholder="Select"
                                error={isBrandNameEmpty}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={searchEngineOptions}
                        disableCloseOnSelect
                        onChange={handleSearchEngine}
                        getOptionLabel={(option) => option.title}
                        key={autoclear}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.title}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Search Engine" placeholder="Select" />
                        )}
                    />

                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        value={Advertiser}
                        multiple
                        onChange={(event, value, reason, details) => setAdvertiser(value)}
                        id="tags-standard"
                        options={props.brandUrl}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Advertiser"
                                placeholder="Select"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        value={brandedUrl}
                        multiple
                        onChange={(event, value, reason, details) => setBrandedUrl(value)}
                        id="tags-standard"
                        options={props.brandedUrl}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Branded URL"
                                placeholder="Select"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="text-right1 bt-1 pt-2">
                        <Button
                            disabled={btnStatus}
                            onClick={handleReportSubmit}
                            variant="contained"
                            color='secondary'
                            type='submit'
                        >Submit</Button>
                    </div>
                </Grid>
            </Grid>
        </div>

    </>
}

export default AutomationReprotSystem;