import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField, Typography,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';
import validateEmail from '../../../utils/validateEmail'
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    button: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    label: {
        display: 'flex',
        justifySelf: 'flex-start',
        float: "left",
        justifyContent: 'flex-start',
        flexShrink: 0,

    }
}));

function CreateComplianceForm(props) {

    const { brands } = props;

    const [brandName, setBrandName] = useState('');
    const [description, setDescription] = useState('');
    const [webSiteDomainUrls, setWebSiteDomainUrls] = useState([]);
    const [exclusionTerms, setExclusionTerms] = useState([]);
    const [emailAlerts, setEmailAlerts] = useState([]);

    const [newUrl, setNewUrl] = useState('');
    const [newTerm, setNewTerm] = useState('');
    const [newEmail, setNewEmail] = useState('');

    const [lastDomainChipCreatedBySpace, setLastDomainChipCreatedBySpace] = useState(false);
    const [lastEmailChipCreatedBySpace, setLastEmailChipCreatedBySpace] = useState(false);

    const [error, setError] = useState(false);
    const history = useHistory();

    // Web site domain url
    const handleWebSiteDomainUrlChange = (event, newValue, reason) => {

        setWebSiteDomainUrls(newValue.filter(option => option.trim() !== ''));
        setLastDomainChipCreatedBySpace(false);
    };


    const handleWebSiteDomainUrlOnKeyDown = (event) => {

        if (event.key === ' ' || event.key === 'Tab' && newUrl !== '' && newUrl !== ' ') {

            let urls = webSiteDomainUrls;
            if (!urls.includes(newUrl)) {

                urls.push(newUrl);
            }
            handleWebSiteDomainUrlChange(event, urls, '');
            setNewUrl('');
            setLastDomainChipCreatedBySpace(true);
        } else
            if (event.key === 'Backspace' && lastDomainChipCreatedBySpace && newUrl === '') {

                let urls = webSiteDomainUrls;
                urls.pop();
                handleWebSiteDomainUrlChange(event, urls, '');
            }
    };

    const handleWebSiteDomainUrlBlur = (event) => {
        if (newUrl !== '' && newUrl !== ' ') {
            let urls = webSiteDomainUrls;
            if (!urls.includes(newUrl)) {
                urls.push(newUrl);
            }
            handleWebSiteDomainUrlChange(event, urls, '');
            setNewUrl('');
        }
    };

    const handleWebSiteDomainUrlDelete = (event, item) => {
        setWebSiteDomainUrls(
            webSiteDomainUrls.filter(
                option => option !== item
            )
        );
    };

    // Exclusion term
    const handleExclusionTermChange = (event, newValue, reason) => {
        setExclusionTerms(newValue.filter(option => option.trim() !== ''));
        setLastDomainChipCreatedBySpace(false);
    };
    const handleExclusionTermOnKeyDown = (event) => {

        if (event.key === 'Backspace' && lastDomainChipCreatedBySpace && newTerm === '') {
            let urls = exclusionTerms;
            urls.pop();
            handleExclusionTermChange(event, urls, '');
        }
    };

    const handleExclusionTermBlur = (event) => {
        if (newTerm !== '' && newTerm !== ' ') {
            let urls = exclusionTerms;
            if (!urls.includes(newTerm)) {
                urls.push(newTerm);
            }
            handleExclusionTermChange(event, urls, '');
            setNewTerm('');
        }
    };

    const handleExclusionTermDelete = (event, item) => {
        setExclusionTerms(
            exclusionTerms.filter(
                option => option !== item
            )
        );
    };



    // Email elert
    const handleEmailAlertsChange = (event, newValue, reason) => {
     
        if (validateEmail(newValue)) {
            setEmailAlerts(newValue.filter(option => option.trim() !== ''));
            setLastEmailChipCreatedBySpace(false);
        }


    };

    const handleEmailAlertsOnKeyDown = (event) => {
      
        if (event.key === 'Tab' && newEmail !== '' && newEmail !== ' ') {
            let emails = emailAlerts;
            if (!emails.includes(newEmail)) {

              
                if (validateEmail([newEmail])) {
                    emails.push(newEmail);

                }

            }
            handleEmailAlertsChange(event, emails, '');
            setNewEmail('');
            setLastEmailChipCreatedBySpace(true);
        } else
            if (event.key === 'Backspace' && lastEmailChipCreatedBySpace && newEmail === '') {
                let emails = emailAlerts;
                emails.pop();
                handleEmailAlertsChange(event, emails, '');
            }
    };
    const handleEmailAlertsOnBlur = (event) => {
     
        if (newEmail !== '' && newEmail !== ' ') {
            let emails = emailAlerts;
            if (!emails.includes(newEmail)) {
                emails.push(newEmail);
            }
            handleEmailAlertsChange(event, emails, '');
            setNewEmail('');
        }
    };

    const handleEmailAlertsDelete = (event, item) => {
    
        setEmailAlerts(
            emailAlerts.filter(
                option => option !== item
            )
        );
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.saveCompliance({
            brandId: brandName.id,
            brandName: brandName.brandName,
            description: description,
            urls: webSiteDomainUrls.join(),
            alertEmails: emailAlerts.join(),
            terms: exclusionTerms.join(),
        });

    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Card>
                        <CardHeader
                            avatar={
                                <IconButton aria-label='Go Back' edge='start' onClick={() => history.goBack()}>
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                            }
                            title='Add Compliance'
                            titleTypographyProps={
                                { variant: 'h5' }
                            }
                            subheaderTypographyProps={
                                { variant: 'body1' }
                            }
                            subheader='Complete the following fields and select create to add a new compliance for you!'
                        />
                        <Divider />
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                {/* compliance Brand Name*/}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <InputLabel id='ad-brand-label'>Brands Name</InputLabel>
                                            <Select
                                                labelId='ad-brand-label'
                                                id='ad-brand'
                                                value={brandName}
                                                onChange={event => setBrandName(event.target.value)}
                                                input={<Input />}

                                            >
                                                {brands.map((brand) => (
                                                    <MenuItem key={brand.id} value={brand}>
                                                        {brand.brandName}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* compliance Description*/}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <TextField
                                                id='compliance-description'
                                                label='Compliance Description'
                                                value={description}
                                                onChange={event => setDescription(event.target.value)}
                                                size='small'
                                                required
                                                error={description.length > 100}
                                            />
                                            {description.length > 100 &&
                                                <FormHelperText error={description.length > 100}>
                                                    This field is limited to 100 characters
                                                </FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Compliance Website Domain URLs */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={error}>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                id='website-domain-urls'
                                                options={[]}
                                                onKeyDown={handleWebSiteDomainUrlOnKeyDown}
                                                value={webSiteDomainUrls}
                                                onChange={
                                                    (event, newValue, reason) => {

                                                        handleWebSiteDomainUrlChange(event, newValue, reason);
                                                    }}

                                                onInputChange={(event, value, reason) => {
                                                    setNewUrl(value.trim());

                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            {...getTagProps({ index })}
                                                            label={option}
                                                            size='small'
                                                            onDelete={
                                                                (event) =>
                                                                    handleWebSiteDomainUrlDelete(event, option)
                                                            }
                                                        />
                                                    )
                                                    )}
                                                renderInput={params => (
                                                    <>
                                                        <TextField
                                                            {...params}

                                                            inputProps={{ ...params.inputProps, value: newUrl }}
                                                            label='Website Domain URLs*'
                                                            onBlur={handleWebSiteDomainUrlBlur}
                                                        // required

                                                        />


                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Compliance Exclusion terms */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={error}>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                id='blocklist-terms'
                                                options={[]}
                                                onKeyDown={handleExclusionTermOnKeyDown}
                                                value={exclusionTerms}
                                                onChange={
                                                    (event, newValue, reason) =>
                                                        handleExclusionTermChange(event, newValue, reason)
                                                }
                                                onInputChange={(event, value, reason) => {
                                                    setNewTerm(value);
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            {...getTagProps({ index })}
                                                            label={option}
                                                            size='small'
                                                            onDelete={
                                                                (event) =>
                                                                    handleExclusionTermDelete(event, option)
                                                            }
                                                        />
                                                    )
                                                    )}
                                                renderInput={params => (
                                                    <>
                                                        <TextField
                                                            {...params}

                                                            inputProps={{ ...params.inputProps, value: newTerm }}
                                                            label='Blocklist Terms*'
                                                            onBlur={handleExclusionTermBlur}
                                                        // required
                                                        />
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Email alerts */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={error}>
                                            <Autocomplete

                                                multiple
                                                freeSolo
                                                id='email-alerts'
                                                options={[]}
                                                onKeyDown={handleEmailAlertsOnKeyDown}
                                                value={emailAlerts}
                                                onChange={
                                                    (event, newValue, reason) =>
                                                        handleEmailAlertsChange(event, newValue, reason)
                                                }
                                                onInputChange={(event, value, reason) => {
                                                    setNewEmail(value.trim());
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            {...getTagProps({ index })}
                                                            label={option}
                                                            size='small'
                                                            onDelete={
                                                                (event) =>
                                                                    handleEmailAlertsDelete(event, option)
                                                            }
                                                        />
                                                    )
                                                    )}
                                                renderInput={params => (
                                                    <TextField

                                                        {...params}
                                                        inputProps={{ ...params.inputProps, value: newEmail }}
                                                        onBlur={handleEmailAlertsOnBlur}
                                                        label='Email Alerts*'
                                                    // required
                                                    />

                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Buttons */}
                                <Grid container spacing={3}>
                                    <Grid container item justify="flex-end" xs={12}>
                                        <Button
                                            variant="text"
                                            className={classes.button}
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type='submit'
                                            className={classes.button}
                                            disabled={
                                                !brandName ||
                                                !description ||
                                                description.length > 100 ||
                                                !webSiteDomainUrls.length ||
                                                !exclusionTerms.length ||
                                                !emailAlerts.length

                                            }
                                        >
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

CreateComplianceForm.propTypes = {};

export default CreateComplianceForm;
