import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IconButton, makeStyles, MenuItem, Select, Typography, Grid } from '@material-ui/core';
import permissions from '../../../permissions';

import AccessControl, { check } from '../../RBAC/AccessControl';
import rules from '../../../rbac-rules';
import { useSelector } from 'react-redux';

import { KeyboardBackspace as KeyboardBackspaceIcon, CreateSharp as CreateSharpIcon } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    }
}));
function BrandName(props) {
    const history = useHistory();
    const classes = useStyles();
    const { compliance, updateCompliance, saveCompliance, brands } = props;
    const [isEditing, setEditing] = useState(false);
    const [brandId, setBrandId] = useState(compliance.brandId);
    const [brandName, setBrandName] = useState(compliance.brandName);
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.compliances.update.full]);

    const submit = (brandId, brandName) => {
        
            const updatedCompliance = { ...compliance,  brandId, brandName};
          
            updateCompliance(updatedCompliance);
            saveCompliance({ compliance: updatedCompliance, msg: 'Brand Name updated!' });
     
        setEditing(false);
    };

    const handleChange = (event) => {
        const selectedBrandName = brands.filter(brand => brand.id === event.target.value);
        setBrandId(event.target.value);
        setBrandName(selectedBrandName[0].brandName)
        submit(event.target.value, selectedBrandName[0].brandName);
    }

    const handleMenuItemClick = (event) => {
        setEditing(false);
    }

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Brand Name
            </Typography>
            {isEditing ? (
                <Grid container direction='row' justify='flex-start' alignItems='center'>
                    <div style={{ flex: '0 0 auto' }}>
                        <IconButton aria-label='Go Back' edge='start' onClick={() => history.goBack()}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </div>
                    <div style={{ flex: '1 1 auto' }}>
                        <Select
                            style={{ width: '50%' }}
                            labelId='brand-name-label'
                            id='brand-name'
                            value={brandId}
                            onChange={handleChange}
                        >
                            {brands.length === 0 &&
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                            }
                            {brands.map((brand) => (
                                <MenuItem key={brand.id} value={brand.id} onClick={handleMenuItemClick}>
                                    {brand.brandName}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </Grid>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '0 0 auto' }}>
                        <IconButton aria-label='Go Back' edge='start' onClick={() => history.goBack()}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </div>
                    <div style={{ flex: '1 1 auto' }}>
                        <Typography
                            component='span'
                            variant='h3'
                            {...(canEdit &&
                                { onClick: () => setEditing(true) }
                            )}
                        >
                            {brandName}
                            <AccessControl allowedPermissions={[permissions.compliances.update.full]}>
                                <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft} onClick={() => setEditing(true)}>
                                    <CreateSharpIcon fontSize='small' color='secondary' />
                                </IconButton>
                            </AccessControl>
                        </Typography>
                    </div>
                </div>
            )}
        </>
    );
}

BrandName.propTypes = {
    classes: PropTypes.object,
    compliance: PropTypes.object,
    updateCompliance: PropTypes.func,
    saveCompliance: PropTypes.func,
    brands: PropTypes.array
};

export default BrandName;

