import React, {useState} from 'react';
import {connect} from 'react-redux';
import Main from 'components/Layout/Main';
import {useHistory, useParams} from 'react-router-dom';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import UserEditComponent from 'components/Users/Edit';
import {successToast, errorToast} from 'actions';
import routes from 'routes';
import roles from 'roles';
import permissions from 'permissions';
import AccessControl from 'components/RBAC/AccessControl';
import Forbidden from 'components/Forbidden';
import useUser from 'api/useUser';
import useAgencies from 'api/useAgencies';

function EditUserContainer(props) {

    const params = useParams();
    let history = useHistory();

    // Load all the agencies
    const {isLoading: isLoadingAgencies, agencies} = useAgencies();
    const [error, setError] = useState(
        {
            field: "",
            message: "",
        }
    );

    // Fetch user
    const {user} = useUser({}, [], true, params.userId);

    // Update User
    const updateUser = async (user) => {
        await axios.post(`${routes.users.endpoint}/${params.userId}`, user)
            .then(() => {
                props.successToast(`${user.name} successfully updated!`);
                history.push(routes.users.path);
            })
            .catch(error => {
                let msg = error.response.data.message
                let err;
                if (msg.includes('email') || msg.includes('username')) {
                    err = {
                        field: 'email',
                        message: msg.includes('username') ?
                            'Email cannot contain spaces' :
                            msg.includes('(') ?
                                msg.substring(0, msg.indexOf('(')) :
                                msg,
                    }
                } else {
                    err = {
                        field: '',
                        message: '',
                    }
                    props.errorToast(msg)
                }
                setError(err);
            })
    };
    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.users.update]} noAccess={() => <Forbidden/>}>
                {isLoadingAgencies || user === null ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <UserEditComponent user={user} agencies={agencies}
                                       roles={roles} updateUser={updateUser} error={error}/>
                )}
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(EditUserContainer);
