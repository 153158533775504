import React, { useState, useEffect } from "react";
import { Button, IconButton, FormLabel, FormControlLabel, Checkbox, TextField, Grid, Paper, styled, Box, Typography, Container } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultipleValueTextInput from 'react-multivalue-text-input';
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

{/**This component is to create auto report */ }
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
    repcont: {
        textAlign: 'center',
    },
    repbtn: {
        float: 'left',

    },
    reptitle: {
        display: 'inline-block',
        marginTop: '10px'
    }

}));

function WeeklyAutomationReprotSystem(props) {

    const classes = useStyles();

    const [btnStatus, setBtnStatus] = useState(false);
    const [reportName, setReportName] = useState('');
    const [agencyName, setAgencyName] = useState('');
    const [agencyId, setAgencyId] = useState('');
    const [brandName, setBrandName] = useState('');
    const [brandedUrlStatus, setBrandedUrlStatus] = useState(null);
    const [notInSearchTerm, setNotInSearchTerm] = useState(null);
    const [notLikeSearchTerm, setNotLikeSearchTerm] = useState(null);
    const [notInAdHostList, setNotInAdHostList] = useState([]);
    const [notLikeAdHostList, setNotLikeAdHostList] = useState([]);
    const [brandedUrlList, setBrandedUrlList] = useState([]);
    const [brandedUrlValue, setBrandedUrlValue] = useState(null);

    const [brandId, setBrandId] = useState('');
    const [brandNameList, setBrandNameList] = useState([]);

    const [notIn, setNotIn] = useState(false);
    const [notLike, setNotLike] = useState(false);

    const [isReportNameEmpty, setIsReportNameEmpty] = useState(false);
    const [isAgencyNameEmpty, setIsAgencyNameEmpty] = useState(false);
    const [isBrandNameEmpty, setIsBrandNameEmpty] = useState(false);
    const [isNotInSearchTermEmpty, setIsNotInSearchTermEmpty] = useState(false);
    const [isNotLikeSearchTermEmpty, setIsNotLikeSearchTermEmpty] = useState(false);
    const [isNotInAdHostListEmpty, setIsNotInAdHostListEmpty] = useState(false);
    const [isNotLikeAdHostListEmpty, setIsNotLikeAdHostListEmpty] = useState(false);
    const [isBrandedUrlListEmpty, setIsBrandedUrlListEmpty] = useState(false);
    const [isBrandedUrlvalueEmpty, setIsBrandedUrlvalueEmpty] = useState(false);
    const [autoclear, setAutoclear] = useState(true);
    const agencyNameOptions = [];
    const brandNameOptions = [];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getBrandList/${brandId}`);

                setBrandNameList(result.data);

            } catch (error) {
                console.log(error);
            }

        };
        fetchData();

    }, [brandId])



    for (let i = 0; i < props.agencyName.length; i++) {
        agencyNameOptions.push({ id: props.agencyName[i][0], agency: props.agencyName[i][1] })
    }
    for (let i = 0; i < brandNameList.length; i++) {
        brandNameOptions.push({ id: brandNameList[i][0], brandName: brandNameList[i][1] })
    }

    const handleAgencyName = (event, value, reason, details) => {

        setIsAgencyNameEmpty(false);
        if (value !== null) {
            setAgencyName(value.agency);
            setAgencyId(value.id);
            setBrandId(value.id)

        } else {
            setAgencyName('');
            setAgencyId('');
            setBrandId('')
        }

    }
    const handleBrandName = (event, value, reason, details) => {
        setIsBrandNameEmpty(false)
        if (value !== null) {
            setBrandName(value.id);

        } else {
            setBrandName('');
        }

    }

    const handleNotIn = (event) => {
        setNotIn(!notIn);
        setNotLike(false);
        setNotInSearchTerm(null);
        setNotInAdHostList([]);
        setIsNotInSearchTermEmpty(false);
        setIsNotInAdHostListEmpty(false);
        setIsBrandedUrlListEmpty(false);
        if (event.target.checked) {
            setBrandedUrlStatus(event.target.value);
        } else {
            setBrandedUrlStatus(null);
        }
    }
    const handleNotLike = (event) => {
        setNotLike(!notLike);
        setNotIn(false);
        setNotLikeSearchTerm(null);
        setNotLikeAdHostList([]);
        setIsNotLikeSearchTermEmpty(false);
        setIsNotLikeAdHostListEmpty(false);;
        if (event.target.checked) {
            setBrandedUrlStatus(event.target.value);
        } else {
            setBrandedUrlStatus(null);
        }
    }
    const handleReportSubmit = async (event) => {
        setBtnStatus(true);
        const report = {
            "reportType": props.report,
            "adhostTemp": [],
            "createdAt": "",
            "id": "",
            "reportname": reportName,
            "agencyname": agencyName,
            "agencyid": agencyId,
            "brandid": brandName,
            "adprovider": [],
            "adhost": [],
            "adbrandedurl": [],
            "brandedUrlStatus": brandedUrlStatus,
            "searchTerm": null,
            "hijackAdHostList": [],
            "brandedUrlList": brandedUrlList,
            "brandedUrlValue": brandedUrlValue
        }

        if ((notIn == false) && (notLike == false)) {
            if ((reportName != '' && agencyName != '' && brandName != '')) {
                await axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    url: `${routes.scheduleReport.endpoint}/saveQueryParam`,
                    data: JSON.stringify(report)
                }).then((response) => {
                    if (response.status == 200) {
                        notify();
                        setReportName('');
                        setAgencyName('');
                        setBrandName('');
                        setBtnStatus(false);
                        setAutoclear(!autoclear);
                        props.triggerRefresh();

                    }

                })
                    .catch(error => {
                        console.log(error)
                        setBtnStatus(false);
                    })
            } else {
                if (reportName == '') { setIsReportNameEmpty(true) }
                if (agencyName == '') { setIsAgencyNameEmpty(true) }
                if (brandName == '') { setIsBrandNameEmpty(true) }
                setBtnStatus(false);
                return;
            }
        } else if ((notIn == true) && (notLike == false)) {
            report.searchTerm = notInSearchTerm;
            report.hijackAdHostList = notInAdHostList;
            report.brandedUrlValue = null;
            if (reportName != '' && agencyName != '' && brandName != '' && notInSearchTerm != null && notInAdHostList.length != 0 && brandedUrlList.length != 0) {
                await axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    url: `${routes.scheduleReport.endpoint}/saveQueryParam`,
                    data: JSON.stringify(report)
                }).then((response) => {
                    if (response.status == 200) {
                        notify();
                        setReportName('');
                        setAgencyName('');
                        setBrandName('');
                        setNotInSearchTerm(null);
                        setNotInAdHostList([]);
                        setBrandedUrlList([]);
                        setNotIn(false);
                        setBtnStatus(false);
                        setAutoclear(!autoclear);
                        props.triggerRefresh();
                    }

                })
                    .catch(error => {
                        console.log(error)
                        setBtnStatus(false);
                    })
            } else {
                if (reportName == '') { setIsReportNameEmpty(true) }
                if (agencyName == '') { setIsAgencyNameEmpty(true) }
                if (brandName == '') { setIsBrandNameEmpty(true) }
                if (notInSearchTerm == null) { setIsNotInSearchTermEmpty(true) }
                if (notInAdHostList.length == 0) { setIsNotInAdHostListEmpty(true) }
                if (brandedUrlList.length == 0) { setIsBrandedUrlListEmpty(true) }
                setBtnStatus(false);
                return;
            }

        } else if ((notIn == false) && (notLike == true)) {
            report.searchTerm = notLikeSearchTerm;
            report.hijackAdHostList = notLikeAdHostList;
            report.brandedUrlList = [];
            if (reportName != '' && agencyName != '' && brandName != '' && notLikeSearchTerm != null && notLikeAdHostList.length != 0 && brandedUrlValue != null) {
                await axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    url: `${routes.scheduleReport.endpoint}/saveQueryParam`,
                    data: JSON.stringify(report)
                }).then((response) => {
                    if (response.status == 200) {
                        notify();
                        setReportName('');
                        setAgencyName('');
                        setBrandName('');
                        setNotLikeSearchTerm(null);
                        setNotLikeAdHostList([]);
                        setBrandedUrlValue(null);
                        setNotLike(false);
                        setBtnStatus(false);
                        setAutoclear(!autoclear);
                        props.triggerRefresh();
                    }

                })
                    .catch(error => {
                        console.log(error)
                        setBtnStatus(false);
                    })
            } else {
                if (reportName == '') { setIsReportNameEmpty(true) }
                if (agencyName == '') { setIsAgencyNameEmpty(true) }
                if (brandName == '') { setIsBrandNameEmpty(true) }
                if (notLikeSearchTerm == null) { setIsNotLikeSearchTermEmpty(true) }
                if (notLikeAdHostList.length == 0) { setIsNotLikeAdHostListEmpty(true) }
                if (brandedUrlValue == '') { setIsBrandedUrlvalueEmpty(true) }
                setBtnStatus(false);
                return;
            }
        }

    }
    const notify = () => toast.success('Successfully Saved', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return <>
        <div>
            <Container className={classes.repcont} >
                <IconButton aria-label='Go Back' edge='start' onClick={() => props.handleReportSelect('brandcompliance')} className={classes.repbtn}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant="h3" gutterBottom component="div" className={classes.reptitle} >
                    WELCOME TO WEEKLY AUTOMATION REPORT
                </Typography>
            </Container>
            <Grid container spacing={2} className="mb-2">
                <Grid item xs={4}>
                    <TextField
                        value={reportName}
                        id="standard-basic"
                        label="Report Name*"
                        variant="standard"
                        fullWidth="true"
                        error={isReportNameEmpty}
                        onChange={(event) => { setReportName(event.target.value); setIsReportNameEmpty(false) }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete

                        id="tags-standard"
                        options={agencyNameOptions}
                        getOptionLabel={(option) => option.agency}
                        key={autoclear}
                        onChange={handleAgencyName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Agency Name*"
                                placeholder="Select"
                                error={isAgencyNameEmpty}
                            />
                        )}
                    />

                </Grid>
                <Grid item xs={4}>
                    <Autocomplete

                        id="tags-standard"
                        options={brandNameOptions}
                        getOptionLabel={(option) => option.brandName}
                        key={autoclear}
                        onChange={handleBrandName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Brand Name*"
                                placeholder="Select"
                                error={isBrandNameEmpty}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormLabel component="legend">Assign responsibility</FormLabel>
                    <FormControlLabel control={<Checkbox checked={notIn} value="option1" onChange={(event) => handleNotIn(event)} />} label="NOT IN" />
                    <FormControlLabel control={<Checkbox checked={notLike} value="option2" onChange={(event) => handleNotLike(event)} />} label="NOT LIKE" />
                </Grid>

                {notIn ?
                    <>
                        <Grid item xs={4}>
                            <TextField
                                value={notInSearchTerm}
                                id="standard-basic"
                                label="Search Term*"
                                variant="standard"
                                fullWidth="true"
                                error={notIn ? isNotInSearchTermEmpty : null}
                                onChange={(event) => { setNotInSearchTerm(event.target.value); setIsNotInSearchTermEmpty(false) }}
                            />
                        </Grid>
                        <Grid item xs={4} className="multivaluecustominput">
                            <MultipleValueTextInput

                                onItemAdded={(item, allItems) => { setNotInAdHostList([...notInAdHostList, item]); setIsNotInAdHostListEmpty(false) }}
                                onItemDeleted={(item, allItems) => setNotInAdHostList(notInAdHostList.filter((value) => value != item))}
                                label="Brand Hijack Ad Host List*"
                                name="item-input"
                                placeholder="Ad Host List."
                                style={notIn && isNotInAdHostListEmpty ? { borderColor: "red" } : null}
                            />

                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4} className="multivaluecustominput">
                            <MultipleValueTextInput

                                onItemAdded={(item, allItems) => { setBrandedUrlList([...brandedUrlList, item]); setIsBrandedUrlListEmpty(false) }}
                                onItemDeleted={(item, allItems) => setBrandedUrlList(brandedUrlList.filter((value) => value != item))}
                                label="Brand Hijack Branded URL List*"
                                name="item-input"
                                placeholder="Branded URL List."
                                style={notIn && isBrandedUrlListEmpty ? { borderColor: "red" } : null}
                            />

                        </Grid>
                    </>
                    : null}

                {notLike ?
                    <>
                        <Grid item xs={4}>
                            <TextField
                                value={notLikeSearchTerm}
                                id="standard-basic"
                                label="Search Term*"
                                variant="standard"
                                fullWidth="true"
                                error={notLike ? isNotLikeSearchTermEmpty : null}
                                onChange={(event) => { setNotLikeSearchTerm(event.target.value); setIsNotLikeSearchTermEmpty(false) }}
                            />
                        </Grid>
                        <Grid item xs={4} className="multivaluecustominput">
                            <MultipleValueTextInput

                                onItemAdded={(item, allItems) => { setNotLikeAdHostList([...notLikeAdHostList, item]); setIsNotLikeAdHostListEmpty(false) }}
                                onItemDeleted={(item, allItems) => setNotLikeAdHostList(notLikeAdHostList.filter((value) => value != item))}
                                label="Brand Hijack Ad Host List*"
                                name="item-input"
                                placeholder="Ad Host List."
                                style={notLike && isNotLikeAdHostListEmpty ? { borderColor: "red" } : null}
                            />

                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={brandedUrlValue}
                                id="standard-basic"
                                label="Brand Hijack Branded URL List Value*"
                                variant="standard"
                                fullWidth="true"
                                placeholder="Branded URL Value."
                                error={notLike ? isBrandedUrlvalueEmpty : null}
                                onChange={(event) => { setBrandedUrlValue(event.target.value); setIsBrandedUrlvalueEmpty(false) }}
                            />
                        </Grid>
                    </>
                    : null}
                <Grid item xs={12}>
                    <div className="text-right1 bt-1 pt-2">
                        <Button
                            onClick={handleReportSubmit}
                            variant="contained"
                            color='secondary'
                            type='submit'
                            disabled={btnStatus}
                        >Submit</Button>
                    </div>
                </Grid>
            </Grid>
        </div>

    </>
}

export default WeeklyAutomationReprotSystem;