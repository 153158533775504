const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const routes = {
    reports: {endpoint: `${BASE_URL}/report`, path: '/reports', pageTitle: 'Reports', menuTitle: 'Reports'},
    violations: {endpoint: `${BASE_URL}/ad`, path: '/violations', pageTitle: 'Violations', menuTitle: 'Violations'},
    brands: {endpoint: `${BASE_URL}/brand`, path: '/brands', pageTitle: 'Your Brands', menuTitle: 'Brands'},
    agencies: {endpoint: `${BASE_URL}/agency`, path: '/agencies', pageTitle: 'Agency Management', menuTitle: 'Agencies'},
    users: {endpoint: `${BASE_URL}/user`, path: '/users', pageTitle: 'User Management', menuTitle: 'Users'},
    scheduleReport: {endpoint: `${BASE_URL}/scheduleReport`, path: '/scheduleReports', pageTitle: 'Schedule Reports', menuTitle: 'Schedule Reports'},

    compliances: {endpoint: `${BASE_URL}/compliance`, path: '/compliances', pageTitle: 'Direct Placement Compliance', menuTitle: 'Compliance'},
    // Summary
    summary: {endpoint: `${BASE_URL}/summary`, path: '/summary', pageTitle: 'Summary Data', menuTitle: 'Summary'},
    scheduleCompliance: {endpoint: `${BASE_URL}/ComplianceSchedule`, path: '/scheduleCompliance', pageTitle: 'Schedule Compliance', menuTitle: 'Schedule Compliance'},
    scrapCompliance: {endpoint: `${BASE_URL}/scraper`},
    searchTerms: {
        headlinesTerms: {path: 'headlineterms'},
        searchTerms: {path: 'searchterms'},
        genericTerms: {path: 'chemicalnames'},
        onLabelTerms: {path: 'onlabelterms'},
        offLabelTerms: {path: 'offlabelterms'},
       
    },
    rule: {endpoint: `${BASE_URL}/rule`},
    adProvider: {endpoint: `${BASE_URL}/adprovider`},
    brandUrls: {endpoint: `${BASE_URL}/brandurl`},
    searchTerm: {endpoint: `${BASE_URL}/searchterm`}
};

export default routes;
