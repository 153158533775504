import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { Typography, TextField, makeStyles, Chip, IconButton, FormHelperText } from '@material-ui/core';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useSelector} from 'react-redux';
import AccessControl, {check} from 'components/RBAC/AccessControl';
import rules from 'rbac-rules';
import permissions from 'permissions';

const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    }
}));

function DomainUrls(props) {

    const [isEditing, setEditing] = useState(false);
    const [readyToSave, setReadyToSave] = useState(false);
    const [firstTimeClick, setFirstTimeClick] = useState(true);
    const [newUrl, setNewUrl] = useState('');
    const [lastCreatedBySpace, setLastCreatedBySpace] = useState(false);
    const { brand, updateBrand, saveBrand } = props;
    const [brandDomainUrls, setBrandDomainUrls] = useState(brand.brandDomainUrls === null ? [] : brand.brandDomainUrls);
    const classes = useStyles();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.update.full]);

    const submit = () => {
        if (brandDomainUrls.length <= 30) {
            setEditing(false);
            setReadyToSave(false);
            setFirstTimeClick(true);
            if (brandDomainUrls !== brand.brandDomainUrls) {
                
                const updatedBrand = { ...brand, brandDomainUrls };
                
                saveBrand({ brand: updatedBrand, msg: 'Domain URLs updated!' });
                updateBrand(updatedBrand);
            }
        }
    };

    const onBlur = () => {
        submit(brand);
    };

    const handleOnKeyDown = (event) => {
        if (event.key === ' ' && newUrl !== '' && newUrl !== ' ') {
            let urls = brandDomainUrls;
            if (!urls.includes(newUrl)) {
                urls.push(newUrl);
            }
            handleBrandDomainUrlChange(event, urls, '');
            setNewUrl('');
            setReadyToSave(true);
            setLastCreatedBySpace(true);
        } else if (event.key === 'Enter' && readyToSave) {
            submit(brand);
        } else if (event.key === 'Enter' && firstTimeClick) {
            submit(brand);
        } else if (event.key === 'Backspace' && lastCreatedBySpace && newUrl === '') {
            let urls = brandDomainUrls;
            urls.pop();
            handleBrandDomainUrlChange(event, urls, '');
        }
    };

    const handleBrandDomainUrlChange = (event, newValue, reason) => {
        setBrandDomainUrls(newValue.filter(value => value.trim() !== ''));
        setLastCreatedBySpace(false);
    };

    const handleBrandDomainUrlDelete = (event, url) => {
        setReadyToSave(true);
        setBrandDomainUrls(brandDomainUrls.filter(option => option !== url));
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Brand Website Domain URLs 
            </Typography>

            {isEditing ? (
                <Autocomplete
                    multiple
                    freeSolo
                    id='brand-domain-urls'
                    options={[]}
                    onKeyDown={handleOnKeyDown}
                    value={brandDomainUrls}
                    onChange={(event, newValue, reason) =>
                        handleBrandDomainUrlChange(event, newValue, reason)}
                    onInputChange={(event, value, reason) => {
                        setFirstTimeClick(false);
                        setNewUrl(value.trim());
                        setReadyToSave( value.trim() === '');
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                                <Chip
                                    {...getTagProps({index})}
                                    label={option}
                                    onDelete={event => handleBrandDomainUrlDelete(event, option)}
                                    size='small'
                                />
                            )
                        )}
                    renderInput={params => (
                            <>
                                <TextField {...params} inputProps={{...params.inputProps, value: newUrl}} onBlur={onBlur}
                                           size='small' error={brandDomainUrls.length > 30} autoFocus />
                                {brandDomainUrls.length > 30 &&
                                    <FormHelperText error={brandDomainUrls.length > 30}>This field is limited to 30 URLs</FormHelperText>
                                }
                                {/*{brandDomainUrls.length === 0 &&*/}
                                {/*    <FormHelperText error={brandDomainUrls.length === 0}>This field is required</FormHelperText>*/}
                                {/*}*/}
                            </>)}
                />
            ) : (
                <div
                    className={classes.chipRoot}
                    {...(canEdit &&
                        {onClick: () => setEditing(true)}
                    )}
                >
                    {brandDomainUrls && brandDomainUrls.map((domain, idx) => (
                        <Chip key={idx} label={domain} size='small' />
                    ))}
                    <AccessControl allowedPermissions={[permissions.brands.update.full]}>
                        <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft} onClick={() => setEditing(true)}>
                            <CreateSharpIcon fontSize='small' color='secondary' />
                        </IconButton>
                    </AccessControl>
                </div>
            )}
        </>
    );
}

DomainUrls.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default DomainUrls;
