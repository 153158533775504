import { Grid, Switch, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { check } from "../../RBAC/AccessControl";
import rules from "../../../rbac-rules";
import { useSelector } from "react-redux";
import permissions from "../../../permissions";

{/** Save and update Google search */}

function DescriptionEnabled(props) {
    const { brand, updateBrand, saveBrand } = props;
    const [descriptionEnabled, setDescriptionEnabled] = useState(brand.descriptionEnabled);
    const { role } = useSelector(state => state.users.currentUser);
    const handleDescriptionEnabledChange = (event) => {
        setDescriptionEnabled(event.target.checked)
        const updatedBrand = { ...brand, descriptionEnabled: event.target.checked };
        saveBrand({ brand: updatedBrand, msg: 'Description enabled updated!' });
        updateBrand(updatedBrand);
        console.log("updated brand", updatedBrand);
    };
    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Enable Description
            </Typography>
            <Grid component="label" container alignItems="center">
                <Grid item>Off</Grid>
                <Grid item>
                    <Switch
                        id="descriptionEnabled"
                        checked={descriptionEnabled}
                        onChange={event => handleDescriptionEnabledChange(event)}
                        name="descriptionEnabled"
                        disabled={!check(rules, role, [permissions.brands.update.basic,])}
                    />
                </Grid>
                <Grid item>On</Grid>
            </Grid>
        </>
    )
}

DescriptionEnabled.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default DescriptionEnabled;
