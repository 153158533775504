//Date formater

function dateConverter(str) {

  var data = "";
  if (str != null) {
    var date = new Date(str), mnth = ("0" + (date.getMonth() + 1))
      .slice(-2), day = ("0" + date.getDate()).slice(-2);
    data = [date.getFullYear(), mnth, day].join("-");
  }
 
  return data;
}
export default dateConverter;
  