import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, TextField, makeStyles, Chip, IconButton, FormHelperText } from '@material-ui/core';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import AccessControl, { check } from 'components/RBAC/AccessControl';
import rules from 'rbac-rules';
import permissions from 'permissions';

const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    chipRoot: {

        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        overflowX: 'hidden',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    }
}));

function DomainUrls(props) {

    const [isEditing, setEditing] = useState(false);
    const [readyToSave, setReadyToSave] = useState(false);
    const [firstTimeClick, setFirstTimeClick] = useState(true);
    const [newUrl, setNewUrl] = useState('');
    const [lastCreatedBySpace, setLastCreatedBySpace] = useState(false);
    const { compliance, updateCompliance, saveCompliance } = props;
    const [complianceDomainUrls, setComplianceDomainUrls] = useState(compliance.urls === null ? [] : compliance.urls.split(","));
    const classes = useStyles();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.compliances.update.full]);

    const submit = () => {

        if (complianceDomainUrls.length >0) {
            setEditing(false);
            setReadyToSave(false);
            setFirstTimeClick(true);
            let urls = compliance.urls.split(",");
            if (complianceDomainUrls !== urls) {
                const updatedCompliance = { ...compliance, "urls": complianceDomainUrls.toString() };
                saveCompliance({ compliance: updatedCompliance, msg: 'Domain URLs updated!' });
                updateCompliance(updatedCompliance);

            }
        }
    };

    const onBlur = () => {
        submit(compliance);
    };

    const handleOnKeyDown = (event) => {
        if (event.key === ' ' && newUrl !== '' && newUrl !== ' ') {
            let urls = complianceDomainUrls;
            if (!urls.includes(newUrl)) {
                urls.push(newUrl);
            }
            handleComplianceDomainUrlChange(event, urls, '');
            setNewUrl('');
            setReadyToSave(true);
            setLastCreatedBySpace(true);
        } else if (event.key === 'Enter' && readyToSave) {
            submit(compliance);
        } else if (event.key === 'Enter' && firstTimeClick) {
            submit(compliance);
        } else if (event.key === 'Backspace' && lastCreatedBySpace && newUrl === '') {
            let urls = complianceDomainUrls;
            urls.pop();
            handleComplianceDomainUrlChange(event, urls, '');
        }
    };

    const handleComplianceDomainUrlChange = (event, newValue, reason) => {
        setComplianceDomainUrls(newValue.filter(value => value.trim() !== ''));
        setLastCreatedBySpace(false);
    };

    const handleComplianceDomainUrlDelete = (event, url) => {
        setReadyToSave(true);
        setComplianceDomainUrls(complianceDomainUrls.filter(option => option !== url));
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Compliance Website Domain URLs
            </Typography>

            {isEditing ? (
                <Autocomplete
                    multiple
                    freeSolo
                    id='brand-domain-urls'
                    options={[]}
                    onKeyDown={handleOnKeyDown}
                    value={complianceDomainUrls}
                    onChange={(event, newValue, reason) =>
                        handleComplianceDomainUrlChange(event, newValue, reason)}
                    onInputChange={(event, value, reason) => {
                        setFirstTimeClick(false);
                        setNewUrl(value.trim());
                        setReadyToSave(value.trim() === '');
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                label={option}
                                onDelete={event => handleComplianceDomainUrlDelete(event, option)}
                                size='small'
                            />
                        )
                        )}
                    renderInput={params => (
                        <>
                            <TextField {...params} inputProps={{ ...params.inputProps, value: newUrl }}
                                onBlur={onBlur}
                                size='small'
                             
                                autoFocus
                            />
                        
                            {complianceDomainUrls.length === 0 &&
                                <FormHelperText error={complianceDomainUrls.length === 0}>This field is required</FormHelperText>
                            }
                        </>)}
                />
            ) : (
                <div
                    className={classes.chipRoot}
                    {...(canEdit &&
                        { onClick: () => setEditing(true) }
                    )}
                >
                    {complianceDomainUrls && complianceDomainUrls.map((domain, idx) => (
                        <Chip key={idx} label={domain} size='small' />
                    ))}
                    <AccessControl allowedPermissions={[permissions.compliances.update.full]}>
                        <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft} onClick={() => setEditing(true)}>
                            <CreateSharpIcon fontSize='small' color='secondary' />
                        </IconButton>
                    </AccessControl>
                </div>
            )}
        </>
    );
}

DomainUrls.propTypes = {
    classes: PropTypes.object,
    brand: PropTypes.object,
    updateBrand: PropTypes.func,
    saveBrand: PropTypes.func
};

export default DomainUrls;
