import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Divider, Grid, } from '@material-ui/core';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import palette from 'theme/palette';
import routes from 'routes';
import { format } from 'date-fns';
import { MaterialUITable1 } from '../../Custom/MaterialUITable';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        marginLeft: '17%'
    },
}));

export default function ViolationsTableComponent(props) {

    const { violations } = props;
    const { violationsCount } = props;
    const classes = useStyles();
    let history = useHistory();

    const [page, setPage] = React.useState(1);


    let count;
    if (violationsCount) {
        count = violationsCount.split('_')[0];
    }

    const [newdata, setdata] = useState([])
    let data = [];

    useEffect(() => {
        setdata(violations)
    }, [])
    if (props.totalpage) {
        data = [...Array(Number(props.totalpage)).keys()];
    }

    const handleChange = (event, value) => {
        setPage(value);
        myFunction(value);

    };

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const updateMatTable = async () => {
        handleToggle();
        try {
            const result1 = await axios.get(`${routes.violations.endpoint}/updateMaterializedView`);
            if (result1.status === 201) { handleClose(); }
        } catch (error) {
            handleClose();
            console.log(error);
        }
    }
    const myFunction = async (id) => {
        handleToggle();
        try {
            const result = await axios.get(`${routes.violations.endpoint}/page/${id}`);

            if (result.status === 200) {
                setdata(result.data.data)
                handleClose();
            }
        } catch (error) {
            handleClose();
            console.log(error);
        }
    }
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Link style={{ textDecoration: 'none', display: 'flex', justifyContent: 'right', padding: '0px 0px 10px' }} >
                        <Button variant='contained' size='small' color='secondary' onClick={updateMatTable}>
                            Update Notification
                        </Button>
                    </Link>
                    <Card variant='outlined'>
                        <CardHeader title={routes.violations.pageTitle}
                            subheader={count + ' unread violations'} />


                        <Divider />
                        <CardContent className={classes.cardContent}>

                            <MaterialUITable1
                                data={newdata}
                                columns={[
                                    { title: 'Brand Name', field: 'brandName' },
                                    { title: 'Ad Title', field: 'adHeadline' },
                                    { title: 'Advertiser URL', field: 'adDisplayUrl' },
                                    {
                                        title: 'Search Term', field: 'searchTerms',
                                        render: rowData => {
                                            return rowData.searchTerms.join(', ');
                                        }
                                    },
                                    { title: 'Search Provider', field: 'adProvider' },
                                    {
                                        title: 'Date/Time',
                                        field: 'adCaptureDate',
                                        render: rowData => {
                                            return format(new Date(rowData.adCaptureDate), "MMM d, yyyy 'at' h:mmaaaa");
                                        },
                                        customFilterAndSearch: (term, rowData) =>
                                            rowData.adCaptureDate === null ? false : (format(new Date(rowData.adCaptureDate), "MMM d, yyyy 'at' h:mmaaaa").toLowerCase()).includes(term.toLowerCase())
                                    },
                                ]}
                                rowStyle={rowData => !rowData.viewedByUser ? { color: palette.primary.dark, fontWeight: '900' } : null}
                                {...{
                                    onRowClick: (event, rowData) => {
                                        const violationId = rowData.adId;
                                        history.push(`${routes.violations.path}/${violationId}`);
                                    }
                                }}
                            />

                            <Pagination
                                count={data.length}
                                page={page}
                                onChange={handleChange}
                                boundaryCount={3}
                                color="primary"
                                showFirstButton
                                showLastButton
                                size="large"
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div>

                <Backdrop className={classes.backdrop} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    );
}
