
import React, { useState, useEffect } from 'react'
import { Button, Link, Box, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core';
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import DailyBrandScheduleReport from './DailyBrandScheduleReport';
import EditDailyBrandScheduleReport from './EditDailyBrandScheduleReport';
import timeConverter from 'utils/timeConverter';



const useStyles = makeStyles((theme) => ({
    root: {
        wordBreak: 'keep-all'
    },
}));

{/** This component is specially desined for schedule column of daily brand list table */ }

function DailyBrandScheduleTableColumn(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const [shceduleDateTime, setShceduleDateTime] = useState([]);
    const [editScheduleId, setEditScheduleId] = useState(null);
    const [editSchedule, setEditSchedule] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getScheduleDateTime/${props.rowData.scheduleId}`);

                setShceduleDateTime(result.data);

            } catch (error) {
                console.log(error);

            }

        };
        fetchData();


    }, [props.refresh]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getScheduleWithId/${editScheduleId}`);

                setEditSchedule(result.data);

            } catch (error) {
                console.log(error);

            }

        };
        if (editScheduleId != null) {
            fetchData();
        }


    }, [editScheduleId])


    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setEditScheduleId(null);
        setOpen(false);
    }
    const handleScheduleLink = (value) => {

        setEditSchedule({})
        setEditScheduleId(value[0]);
        setOpen(true);

    }
    const deleteSchedule = (id) => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/deleteScheduleById/${id}`);

                if (result.data == 'success') {
                    notify();
                    props.triggerRefresh();
                }


            } catch (error) {
                console.log(error);

            }

        };
        fetchData();
    }


    const notify = () => toast.warning('Successfully Deleted', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return (
        <>

            {shceduleDateTime.length > 0 ? (
                shceduleDateTime.map((value) =>
                    <Box sx={{ bgcolor: '#ddebdd', height: '100px', width: '110px', marginBottom: '10px', p: '9px' }}>
                        <IconButton onClick={(event) => deleteSchedule(value[0])} aria-label="delete" size='small' style={{ color: 'red', float: 'right' }}>
                            <Delete />
                        </IconButton>
                        {findReportType(value, handleScheduleLink)}
                    </Box>
                )) : null
            }
            <DailyBrandScheduleReport
                {...props}
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}

            />
            {editScheduleId && Object.keys(editSchedule).length != 0 && <EditDailyBrandScheduleReport
                {...props}
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                editSchedule={editSchedule}
                setEditScheduleId={setEditScheduleId}
            />}
            <Button onClick={(event) => handleOpen()} className={classes.root} variant="contained" color='secondary' type='submit' >Schedule</Button>
        </>
    )
}
export default DailyBrandScheduleTableColumn;

function findReportType(value, handleScheduleLink) {

    if (value[1] == 'Daily') {

        return <Link href="#" onClick={(e) => handleScheduleLink(value)} color='#129bdb'>{value[1] + " " + timeConverter(value[2]) + " " + "EST" + " Start date " + value[3]}</Link>
    }
    if (value[1] == 'Weekly') {


        return <Link href="#" onClick={(e) => handleScheduleLink(value)} color='#129bdb'>{value[1] + " " + value[4] + timeConverter(value[2]) + " " + "EST" + " Start date " + value[3]}</Link>
    }
    if (value[1] == 'Monthly') {

        return <Link href="#" onClick={(e) => handleScheduleLink(value)} color='#129bdb'>{value[1] + " " + timeConverter(value[2]) + " " + "EST" + " Start date " + value[3]}</Link>
    }
    return;
}
