import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, Typography, TextField, Grid, Paper, styled, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import Box from '@material-ui/core/Box';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  alignItems: "center",
  justifyContent: "space-around"
}));
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  }
}));
{/** It is used to create schedule. */ }
const ScheduleCompliance = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const [btnStatus, setBtnStatus] = useState(false);
  const [autoReportId, setAutoReportId] = useState(props.rowData.id);
  const [day, setDay] = useState('Sunday');
  const [startDate, setStartDate] = useState('');
  const [reportType, setReportType] = useState('Daily');
  const [time, setTime] = useState('');
  const [isStartDateEmpty, setIsStartDateEmpty] = useState(false);
  const [isTimeEmpty, setIsTimeEmpty] = useState(false);
  const [isReportTypeEmpty, setIsReportTypeEmpty] = useState(false);
  const [isDayEmpty, setIsDayEmpty] = useState(false);

  const saveSchedule = async () => {
    setBtnStatus(true);
    const schedule = {
      id: '',
      autoComplianceId: autoReportId,
      startDate: startDate,
      complianceReportType: reportType,
      scheduleTime: time,
      scheduleDay: day
    }
    if (reportType == 'Daily') {
      schedule.scheduleDay = null
    }
    console.log("schedule", schedule)
    if (startDate != '' && reportType != '' && reportType != null && day != '' && day != null && time != '') {
      await axios({
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: `${routes.scheduleCompliance.endpoint}/saveComplianceScheduleDetails`,
        data: JSON.stringify(schedule)
      }).then((response) => {
        if (response.status == 200) {
          notify();
          setStartDate('');
          setTime('');
          setReportType('Daily');
          setDay('Sunday');
          setBtnStatus(false);
          props.handleClose();
          history.push(`/`);
          history.push(`${routes.compliances.path}`);

        }

      }).catch(error => {
        console.log(error);
        setBtnStatus(false);
      })
    } else {
      if (startDate == '') { setIsStartDateEmpty(true) }
      if (reportType == '' || reportType == null) { setIsReportTypeEmpty(true) }
      if (day == '' || day == null) { setIsDayEmpty(true) }
      if (time == '') { setIsTimeEmpty(true) }
      setBtnStatus(false);
      return;
    }
  }

  const notify = () => toast.success('Successfully Saved', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  const handleOnClose = () => {
    setIsStartDateEmpty(false);
    setIsTimeEmpty(false);
    setIsDayEmpty(false);
    setIsReportTypeEmpty(false);
    setReportType('Daily');
    setDay('Sunday');
    props.handleClose();
  }
  return (
    <>

      <Modal
        open={props.open}
        onClose={() => handleOnClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,

          backgroundColor: 'white',
          boxShadow: 24,
          borderRadius: 5,
        }}>
          <Box style={{
            padding: '20px',
            backgroundColor: 'green',
          }} >
            <Typography id="modal-modal-title" variant="h3" component="h2" align="center" style={{ color: "white" }}>
              Schedule Compliance
            </Typography>
          </Box>
          <Box style={{
            padding: '25px'
          }}
          >
            <Grid container spacing={4}  >
              <Grid item xs={6}>
                <Typography id="modal-modal-title" variant="h6" >Start Date*</Typography>
                <input type="date" className="customform" value={startDate} onChange={(e) => { setStartDate(e.target.value); setIsStartDateEmpty(false) }} style={isStartDateEmpty ? { borderColor: "red" } : {}} />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  defaultValue={reportType}
                  id="tags-standard"
                  options={['Daily', 'Weekly']}

                  onChange={(event, value) => { setReportType(value); setIsReportTypeEmpty(false); console.log("report>", value) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Report Type*"
                      placeholder="Select"
                      error={isReportTypeEmpty}
                    />
                  )}
                />
              </Grid>
              {reportType == 'Weekly' && <Grid item xs={6}>
                <Autocomplete

                  defaultValue={day}
                  id="tags-standard"
                  options={['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'saturday']}

                  onChange={(event, value) => { setDay(value); setIsDayEmpty(false); console.log(value) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Day*"
                      placeholder="Select"
                      error={isDayEmpty}
                    />
                  )}
                />
              </Grid>}
              <Grid item xs={6}>
                <Typography id="modal-modal-title" variant="h6" >Time*</Typography>
                <input type="time" className="customform" value={time} label="Time*" onChange={(e) => { setTime(e.target.value); setIsTimeEmpty(false) }} style={isTimeEmpty ? { borderColor: "red" } : {}} />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ p: 4 }}>
            <Button onClick={(event) => handleOnClose()} variant="contained" style={{ backgroundColor: 'grey', color: 'white', float: 'right', margin: '10px' }}>
              Close
            </Button>
            <Button
              disabled={btnStatus}
              onClick={saveSchedule}
              variant="contained"
              style={{ backgroundColor: 'green', color: 'white', float: 'right', margin: '10px' }}>
              Schedule
            </Button>
          </Box>
        </Box>
      </Modal>

    </>
  )
}
export default ScheduleCompliance;

