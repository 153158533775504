import React from 'react';
import ViolationsTableComponent from 'components/Violations/List';
import Main from 'components/Layout/Main';
import AccessControl from "../../../components/RBAC/AccessControl";
import permissions from "../../../permissions";
import useViolations from "../../../api/useViolations";
import LinearProgress from "@material-ui/core/LinearProgress";
import useViolationsCount from "../../../api/useViolationsCount";
import Forbidden from "../../../components/Forbidden";
import {useSelector} from "react-redux";

function ViolationsContainer(props) {

    const {isLoading, violations} = useViolations();
    console.log(violations,'violationsviolationsviolations');
    
    const {violationsCount} = useSelector(state => state.violationsCount);
    let count,currentpage,totalpage,totalitem,tcount;
    if(violationsCount){
         count =  violationsCount.split('_')
         tcount=count[0];
        console.log(violationsCount,'violationsCount');
         currentpage =  count[1].split(',')[0];
         totalpage = violationsCount.split(',')[1];
         totalitem = violationsCount.split(',')[2];
        
    }

console.log(`currentpage ${currentpage}, totalpage ${totalpage}, totalitem ${totalitem}`);
    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.violations.list]} noAccess={() => <Forbidden/>}>
                {isLoading ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <ViolationsTableComponent currentpage={currentpage} totalpage={totalpage} totalitem={totalitem} violations={violations} violationsCount={tcount}/>
                )}
            </AccessControl>
        </Main>
    );
}

export default ViolationsContainer;
