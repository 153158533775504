import React, { useState } from 'react';
import { Button, Modal, Typography, TextField, Grid, Paper, styled, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultipleValueTextInput from 'react-multivalue-text-input';
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import validateEmail from 'utils/validateEmail';
import Box from '@material-ui/core/Box';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  alignItems: "center",
  justifyContent: "space-around"
}));

{/** This component is used to edit the schedule report. */ }
const EditScheduleReport = (props) => {


  const [btnStatus, setBtnStatus] = useState(false);
  const [autoReportId, setAutoReportId] = useState(props.rowData.scheduleId);
  const [id, setId] = useState(props.editSchedule.id)

  const [startDate, setStartDate] = useState(props.editSchedule.startDate);
  const [reportType, setReportType] = useState(props.editSchedule.reportType);
  const [day, setDay] = useState(props.editSchedule.scheduleday ? props.editSchedule.scheduleday : 'Sunday');
  const [month, setMonth] = useState(props.editSchedule.schedulemonth ? props.editSchedule.schedulemonth : 'January');
  const [scheduleDate, setScheduleDate] = useState((props.editSchedule.reportType == 'Daily' || props.editSchedule.reportType == 'Weekly') ? null : props.editSchedule.scheduledate);
  

  const [time, setTime] = useState(props.editSchedule.scheduleTime);
  const [emails, setEmails] = useState(props.editSchedule.recipientEmail.split(','));

  const [isStartDateEmpty, setIsStartDateEmpty] = useState(false);
  const [isScheduleDateEmpty, setIsScheduleDateEmpty] = useState(false);
  const [isTimeEmpty, setIsTimeEmpty] = useState(false);
  const [isInValidEmail, setIsInValidEmail] = useState(false);
  const [isReportTypeEmpty, setIsReportTypeEmpty] = useState(false);
  const [isDayEmpty, setIsDayEmpty] = useState(false);
  const [isMonthEmpty, setIsMonthEmpty] = useState(false);

  const saveSchedule = async () => {
    
    if (reportType === null) {
      setIsReportTypeEmpty(true);
      return
    }
    setBtnStatus(true);
    const schedule = {
      id: id,
      autoreportid: autoReportId,
      startDate: startDate,
      reportType: reportType,
      scheduleday: day,
      schedulemonth: month,
      scheduledate: scheduleDate,
      scheduleTime: time,

      recipientEmail: emails
    }
    if (reportType == 'Daily') {
     
        schedule.scheduleday = null;
        schedule.schedulemonth = null;
        schedule.scheduledate = null;
   
      
      if (validateEmail(emails) && startDate != '' && time != '') {
        await axios({
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          url: `${routes.scheduleReport.endpoint}/saveQueryScheduleDetails`,
          data: JSON.stringify(schedule)
        }).then((response) => {
          if (response.status == 200) {
            notify();
            setStartDate('');
            setScheduleDate('');
            setTime('');
            setReportType('Daily');
            setEmails([]);
            setBtnStatus(false);
            props.setEditScheduleId(null)
            props.triggerRefresh();
            props.handleClose();

          }

        }).catch(error => {
          console.log(error);
          setBtnStatus(false);
        })
      } else {
        if (startDate == '') { setIsStartDateEmpty(true) }
        if (time == '') { setIsTimeEmpty(true) }
        if (!validateEmail(emails)) { setIsInValidEmail(true) }
        setBtnStatus(false);
        return;
      }
    }else if(reportType == 'Weekly'){
      schedule.scheduledate = null;
      schedule.schedulemonth = null;

      if (validateEmail(emails) && startDate != '' && time != '' && day != null) {
        await axios({
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          url: `${routes.scheduleReport.endpoint}/saveQueryScheduleDetails`,
          data: JSON.stringify(schedule)
        }).then((response) => {
          if (response.status == 200) {
            notify();
            setStartDate('');
            setScheduleDate('');
            setTime('');
            setReportType('Daily');
            setEmails([]);
            setBtnStatus(false);
            props.handleClose();
            props.triggerRefresh();

          }

        }).catch(error => {
          console.log(error);
          setBtnStatus(false);
        })
      } else {
        if (startDate == '') { setIsStartDateEmpty(true) }
        if (time == '') { setIsTimeEmpty(true) }
        if (day == null) { setIsDayEmpty(true) }
        if (!validateEmail(emails)) { setIsInValidEmail(true) }
        setBtnStatus(false);
        return;
      }
    } else if (reportType == 'Monthly') {
      schedule.scheduleday = null;
      if (validateEmail(emails) && startDate != '' && time != '' && scheduleDate != null && month != null) {
        await axios({
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          url: `${routes.scheduleReport.endpoint}/saveQueryScheduleDetails`,
          data: JSON.stringify(schedule)
        }).then((response) => {
          if (response.status == 200) {
            notify();
            setStartDate('');
            setScheduleDate('');
            setTime('');
            setReportType('Daily');
            setEmails([]);
            setBtnStatus(false);
            props.setEditScheduleId(null)
            props.triggerRefresh();
            props.handleClose();

          }

        }).catch(error => {
          console.log(error);
          setBtnStatus(false);
        })
      } else {
        if (startDate == '') { setIsStartDateEmpty(true) }
        if (scheduleDate == null) { setIsScheduleDateEmpty(true) }
        if (time == '') { setIsTimeEmpty(true) }
        if (month == null) { setIsMonthEmpty(true) }
        if (!validateEmail(emails)) { setIsInValidEmail(true) }
        setBtnStatus(false);
        return;
      }
    }

  }
  const notify = () => toast.success('Successfully Saved', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return (
    <>

      <Modal
        open={props.open}
        onClose={() => { props.handleClose(); setReportType('Daily'); setEmails([]); }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          backgroundColor: 'white',
          boxShadow: 24,
          borderRadius: 5,
        }}>
          <Box style={{
            padding: '20px',
            backgroundColor: 'green',
          }}
          >
            <Typography id="modal-modal-title" variant="h3" component="h2" align="center" style={{ color: "white" }}>
              Schedule Report
            </Typography>
          </Box>
          <Box style={{
            padding: '25px'
          }}
          >
            <Grid container spacing={4}  >
              <Grid item xs={6}>
                <Typography id="modal-modal-title" variant="h6" >Start Date*</Typography>
                <input type="date" className="customform" value={startDate} onChange={(e) => setStartDate(e.target.value)} style={isStartDateEmpty ? { borderColor: "red" } : {}} />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  defaultValue={reportType}
                  id="tags-standard"
                  options={['Daily', 'Weekly', 'Monthly']}
                  onChange={(event, value) => { setReportType(value); setIsReportTypeEmpty(false) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Report Type*"
                      placeholder="Select"
                      error={isReportTypeEmpty}
                    />
                  )}
                />
              </Grid>
              {reportType == 'Weekly' && <Grid item xs={6}>
                <Autocomplete
                  defaultValue={day}
                  id="tags-standard"
                  options={['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'saturday']}
                  onChange={(event, value) => { setDay(value); setIsDayEmpty(false) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Day*"
                      placeholder="Select"
                      error={isDayEmpty}
                    />
                  )}
                />
              </Grid>}
              {reportType == 'Monthly' && <Grid item xs={6}>
                <Autocomplete
                  defaultValue={month}
                  id="tags-standard"
                  options={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                  onChange={(event, value) => {setMonth(value); setIsMonthEmpty(false)}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Month*"
                      placeholder="Select"
                      error = {isMonthEmpty}
                    />
                  )}
                />
              </Grid>}
              {reportType == 'Monthly' &&
                <Grid item xs={6}>
                  <Typography id="modal-modal-title" variant="h6" >Schedule Date*</Typography>
                  <input type="date" className="customform" value={scheduleDate} onChange={(e) => { setScheduleDate(e.target.value); setIsScheduleDateEmpty(false) }} style={isScheduleDateEmpty ? { borderColor: "red" } : {}} />
                </Grid>}
              <Grid item xs={6}>
                <Typography id="modal-modal-title" variant="h6" >Time*</Typography>
                <input type="time" className="customform" value={time} label="Time*" onChange={(e) => setTime(e.target.value)} style={isTimeEmpty ? { borderColor: "red" } : {}} />
              </Grid>
              <Grid item xs={6} className="multivaluecustominput">

                <MultipleValueTextInput
                  onItemAdded={(item, allItems) => { setEmails([...emails, item]) }}
                  onItemDeleted={(item, allItems) => setEmails(emails.filter((value) => value != item))}
                  label="Recipients Email Id*"
                  name="item-input"
                  placeholder="Enter the email id..."
                  values={props.editSchedule.recipientEmail.split(',')}
                  style={isInValidEmail ? { borderColor: "red" } : null}

                />

              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ p: 4 }}>
            <Button onClick={(event) => props.handleClose()} variant="contained" style={{ backgroundColor: 'grey', color: 'white', float: 'right', margin: '10px' }}>
              Close
            </Button>
            <Button 
            disabled={btnStatus}
            onClick={saveSchedule} 
            variant="contained" 
            style={{ backgroundColor: 'green', color: 'white', float: 'right', margin: '10px' }}>
              Schedule
            </Button>
          </Box>
        </Box>
      </Modal>

    </>
  )
}
export default EditScheduleReport;
