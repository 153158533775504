import React, { useState } from "react";
import { Button, IconButton, TextField, Grid, Paper, styled, Typography, Container, } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormHelperText } from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { startOfDay } from 'date-fns';


{/**This component is used to create direct compliance report */ }
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
    repcont: {
        textAlign: 'center',
    },
    repbtn: {
        float: 'left',

    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    reptitle: {
        display: 'inline-block',
        marginTop: '10px'
    }

}));

function ComplianceReprot(props) {

    const { brands, handleComplianceReport, setBrand, urls, terms, description } = props;

    const classes = useStyles();
    
    let location = useLocation();
    let params = qs.parse(location.search, { ignoreQueryPrefix: true, comma: true });
    const [brandName, setBrandName] = useState('');
    const [fromDate, setFromDate] = useState(
        (params.fromDate) ?
            startOfDay(new Date(params.fromDate)) :
            null
    );

    const [toDate, setToDate] = useState(
        (params.toDate) ?
            startOfDay(new Date(params.toDate)) :
            null
    );
    const [websiteDomainUrls, setWebsiteDomainUrls] = useState([]);
    const [searchTerms, setSearchTerms] = useState([]);
    const [complianceId, setComplianceId] = useState('');
    const [isBrandNameError, setIsBrandNameError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    const [toDateError, setToDateError] = useState(false);

    const handleFromDateChange = (date) => {
        setFromDate(date);
        setFromDateError(false);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
        setToDateError(false);
    };
    const handleBrandName = (value) => {
     
        if (value) {
            setBrandName(value.brandName);
            setBrand(value.brandName);
            setIsBrandNameError(false);
            setWebsiteDomainUrls([]);
            setSearchTerms([]);
        } else {
            setBrandName('');
            setBrand(null);
        }

    }
    const handleDescription =(value)=>{
        if(value){
            setComplianceId(value.id);
        }
       
    }

    const handleReportSubmit = async (event) => {

        const isBrandNameError = brandName != '';
        const isFromDateSet = fromDate !== null && fromDate !== '';
        const isToDateSet = toDate !== null && toDate !== '';

        setIsBrandNameError(!isBrandNameError);
        setFromDateError(!isFromDateSet);
        setToDateError(!isToDateSet);

        if (isBrandNameError && isFromDateSet && isToDateSet) {
            const report = {
                "brand": brandName,
                "fromDate": fromDate.toISOString(),
                "toDate": toDate.toISOString(),
                "urls": websiteDomainUrls,
                "searchTerm": searchTerms,
                "complianceId": complianceId
            }
            handleComplianceReport(report);
            
        }


    }
    return <>
        <div>
            <Container className={classes.repcont} >
                <IconButton aria-label='Go Back' edge='start' onClick={() => props.handleReportSelect('brandcompliance')} className={classes.repbtn}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant="h3" gutterBottom component="div" className={classes.reptitle} >
                    WELCOME TO DIRECT-PLACEMENT-COMPLIANCE AUTOMATION REPORT
                </Typography>
            </Container>
            <Grid container spacing={2} className="mb-2">
                <Grid item xs={4}>
                    <Autocomplete

                        id="tags-standard"
                        options={brands}
                        getOptionLabel={(option) => option.brandName}
                        onChange={(event, value, reason, details) => handleBrandName(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Brand Name*"
                                placeholder="Select"
                                error={isBrandNameError}
                            />
                        )}
                    />

                </Grid>
                <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            className={classes.formControl}
                            autoOk
                            format="MMMM do, yyyy"
                            disableFuture
                            variant='inline'
                            label='From Date'
                            value={fromDate}
                            onChange={handleFromDateChange}
                            size='small'
                            error={fromDateError}
                        />
                    </MuiPickersUtilsProvider>
                    <FormHelperText>From date required</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            className={classes.formControl}
                            autoOk
                            format="MMMM do, yyyy"
                            disableFuture
                            variant='inline'
                            label='To Date'
                            value={toDate}
                            onChange={handleToDateChange}
                            size='small'
                            error={toDateError}
                        />
                    </MuiPickersUtilsProvider>
                    <FormHelperText>To date required</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        value={websiteDomainUrls}
                        multiple
                        onChange={(event, value, reason, details) => setWebsiteDomainUrls(value)}
                        id="tags-standard"
                        options={urls}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Website-domain-urls"
                                placeholder="Select"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        value={searchTerms}
                        multiple
                        onChange={(event, value, reason, details) => setSearchTerms(value)}
                        id="tags-standard"
                        options={terms}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Search-terms"
                                placeholder="Select"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        onChange={(event, value, reason, details) => handleDescription(value)}
                        id="tags-standard"
                        options={description}
                        getOptionLabel={(option) => option.description}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Description"
                                placeholder="Select"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="text-right1 bt-1 pt-2">
                        <Button
                            onClick={handleReportSubmit}
                            variant="contained"
                            color='secondary'
                            type='submit'
                        >Submit</Button>
                    </div>
                </Grid>
            </Grid>
        </div>

    </>
}

export default ComplianceReprot;