import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import routes from 'routes';
import permissions from 'permissions';
import rules from 'rbac-rules';
import { check } from 'components/RBAC/AccessControl';

export default () => {
    const [brandUrl, setBrandUrl] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { role } = useSelector(state => state.users.currentUser);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getBrandURL`);

                setBrandUrl(result.data);

            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
        
    }, []);

    return { isLoading, brandUrl };
}
