import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import qs from 'qs';
import { startOfDay } from 'date-fns';
import ToDate from "./ToDate";
import FromDate from "./FromDate";
import { useLocation } from 'react-router-dom';


import {
    Button,
    Box,
    Typography,
    Modal,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';
import Loader from '../Loader';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    button: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
}));


function CreateSummary(props) {
    const { summary, getSummary, saveSummary, deleteSummary } = props;
    let location = useLocation();
    let params = qs.parse(location.search, { ignoreQueryPrefix: true, comma: true });

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [checkedSummaryCount, setCheckedSummaryCount] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openForDelete, setOpenForDelete] = React.useState(false);
    const handleOpenForDelete = () => setOpenForDelete(true);
    const handleCloseForDelete = () => setOpenForDelete(false);

    const [fromDateError, setFromDateError] = useState(false);
    const [fromDate, setFromDate] = useState(
        (params.fromDate) ?
            startOfDay(new Date(params.fromDate)) :
            null
    );
    const [toDate, setToDate] = useState(
        (params.toDate) ?
            startOfDay(new Date(params.toDate)) :
            null
    );
    const [reportError, setReportError] = useState(false);
    const [toDateError, setToDateError] = useState(false);
    const classes = useStyles();

    const handleDelete = () => {
        handleOpenForDelete()
    }

    useEffect(() => {
        const allZero = Object.values(summary).every(value => value === 0);
        setCheckedSummaryCount(allZero)
    }, [summary])

    const handleDeleteSummaryData = async () => {
        handleCloseForDelete()
        const isFromDateSet = fromDate !== null && fromDate !== '';
        const isToDateSet = toDate !== null && toDate !== '';

        if (isFromDateSet && isToDateSet) {
            setIsDeleteLoading(true)
            deleteSummary({
                "dateFrom": fromDate,
                "dateTo": toDate
            }).then((data) => {
                saveSummary(summary).then((data) => {
                    setIsDeleteLoading(false)
                    handleClose()
                }).catch((err) => {
                    setIsDeleteLoading(false)
                })
            })
        } else {
            setIsDeleteLoading(false)
            setFromDateError(!isFromDateSet);
            setToDateError(!isToDateSet);
        }
    }

    const submit = async (event) => {
        event.preventDefault();
        const isFromDateSet = fromDate !== null && fromDate !== '';
        const isToDateSet = toDate !== null && toDate !== '';

        if (isFromDateSet && isToDateSet) {
            setIsLoading(true)
            setDisabled(true)

            let data = await getSummary({
                "dateFrom": fromDate,
                "dateTo": toDate
            });
            console.log("data", data)
            setIsLoading(false)
            setDisabled(false)
        } else {
            setFromDateError(!isFromDateSet);
            setToDateError(!isToDateSet);
        }
    };


    return (
        <>
            {isLoading ? <Loader /> : <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={10} lg={8} xl={6}>
                        <Card>
                            <CardHeader
                                title='Summary'
                                subheader='Complete the following fields and select date to add a new summaary to AdComplyRx!'
                            />
                            <Divider />
                            <CardContent>
                                <form onSubmit={submit}>
                                    <Grid container spacing={3}>
                                        {/* From Date */}
                                        <Grid item xs={12} sm={6}>
                                            <FormControl className={classes.formControl} error={fromDateError}>
                                                <FromDate
                                                    fromDate={fromDate}
                                                    fromDateError={fromDateError}
                                                    setFromDate={setFromDate}
                                                    setFromDateError={setFromDateError}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* To Date */}
                                        <Grid item xs={12} sm={6}>
                                            <FormControl className={classes.formControl} error={toDateError}>
                                                <ToDate
                                                    toDate={toDate}
                                                    toDateError={toDateError}
                                                    setToDate={setToDate}
                                                    setToDateError={setToDateError}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Divider />
                                    <br />
                                    <br />
                                    {/* Generate Report Button*/}
                                    <Grid item xs={3}>
                                        <FormControl className={classes.formControl}>
                                            {/* <Button variant='contained' disabled={props.loadingReport} */}
                                            <Button variant='contained' disabled={disabled}
                                                color='secondary'
                                                size="md"
                                                classes={{
                                                    root: classes.downloadReportButton,
                                                    disabled: classes.downloadReportButtonDisabled
                                                }}
                                                type='submit' onClick={handleOpen}>
                                                Summary
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <div>

                    <Modal
                        open={open}
                        onClose={() => handleClose()}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <Box style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 600,
                            backgroundColor: isDeleteLoading ? "rgba(0, 0, 0, 0.5)" : 'white',
                            boxShadow: 24,
                            borderRadius: 5,
                        }}>
                            <Box style={{
                                padding: '20px',
                                backgroundColor: 'green',
                            }} >
                                <Typography id="modal-modal-title" variant="h3" component="h2" align="center" style={{ color: "white" }}>
                                    Summary Record
                                </Typography>
                            </Box>
                            <Box style={{
                                padding: '25px'
                            }}
                            >
                                <Grid container spacing={4} style={{ position: 'relative' }}>
                                    {isDeleteLoading ? (
                                        <div style={{
                                            position: 'absolute',
                                            top: '10%',
                                            left: '50%', // Center horizontally
                                            transform: 'translateX(-50%)', // Adjust for centering
                                            pointerEvents: 'none',
                                            color: '#fff',
                                            padding: '10px', // Optional padding for better appearance
                                            borderRadius: '5px' // Optional rounded corners
                                        }}>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </div>
                                    ) : (
                                        checkedSummaryCount ? (
                                            <h1>No record found</h1>
                                        ) : (
                                            Object.keys(summary).map(key => (
                                                <Grid container item xs={12} spacing={2} key={key}>
                                                    <Grid item xs={6}>
                                                        <Typography id="modal-modal-description" variant="h4" sx={{ mt: 2 }}>
                                                            {`${key}:  `}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography id="modal-modal-description" variant="h4" sx={{ mt: 2 }}>
                                                            {`${summary[key]}`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        )
                                    )}
                                </Grid>


                            </Box>
                            {/* <Divider /> */}
                            <Box sx={{ p: 4 }} >

                                <div>
                                    <Button
                                        onClick={handleClose}
                                        variant="contained"
                                        style={{ backgroundColor: 'grey', color: 'white', float: 'right', margin: '10px' }}
                                    >
                                        Close
                                    </Button>

                                    {checkedSummaryCount ? null : (
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: 'Red', color: 'white', float: 'right', margin: '10px' }}
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </div>

                            </Box>


                        </Box>
                    </Modal>

                    <Modal
                        open={openForDelete}
                        onClose={() => handleCloseForDelete()}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 600,

                            backgroundColor: 'white',
                            boxShadow: 24,
                            borderRadius: 5,
                        }}>
                            <Box style={{
                                padding: '20px',
                                backgroundColor: 'green',
                            }} >
                                <Typography id="modal-modal-title" variant="h3" component="h2" align="center" style={{ color: "white" }}>
                                    Do you want to delete all Summary Record?
                                </Typography>
                            </Box>
                            <Divider />
                            <Box sx={{ p: 4 }}>
                                <Button onClick={(event) => handleCloseForDelete()} variant="contained" style={{ backgroundColor: 'grey', color: 'white', float: 'right', margin: '10px' }}>
                                    No
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: 'Red', color: 'white', float: 'right', margin: '10px' }}
                                    onClick={handleDeleteSummaryData}
                                >
                                    Yes
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </div>

            </div>}

        </>


    );
}

CreateSummary.propTypes = {};

export default CreateSummary;
