import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {FormControl, FormHelperText} from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";


function ToDate(props) {

    const {toDate, toDateError, setToDate, setToDateError} = props;
    const [curDate, setCurDate] = useState(new Date());
    const handleToDateChange = (date) => {
        setToDate(date);
        setToDateError(false);
    };
console.log("toDate>>", toDate)
    return(
        <>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    format="MMMM do, yyyy"
                    disableFuture
                    maxDate = { new Date( curDate.setFullYear( curDate.getFullYear() - 1))}
                    variant='inline'
                    label='To Date'
                    value={toDate}
                    // value={toDate}
                    onChange={handleToDateChange}
                    size='small'
                    error={toDateError}
                />
            </MuiPickersUtilsProvider> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
  <DatePicker
    autoOk
    format="MMMM do, yyyy"
    disableFuture
    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
    variant="inline"
    label="To Date"
    value={toDate}
    onChange={handleToDateChange}
    size="small"
    error={toDateError}
  />
</MuiPickersUtilsProvider>
{/* <FormHelperText>From date required</FormHelperText> */}

            <FormHelperText>To date required</FormHelperText>
        </>
    );

}

ToDate.propTypes = {
    toDate: PropTypes.object,
    toDateError: PropTypes.bool,
    setToDate: PropTypes.func,
    setToDateError: PropTypes.func,
};

export default ToDate;