import React,{useEffect} from 'react';

import ViolationComponent from 'components/Violations/Violation';
import Main from 'components/Layout/Main';
import clsx from 'clsx';
import roles from "../../../roles";
import {Grid, makeStyles, Paper} from '@material-ui/core';
import AccessControl, {check} from "../../../components/RBAC/AccessControl";
import permissions from "../../../permissions";
import useViolation from "../../../api/useViolation";
import {useParams} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import routes from "../../../routes";
import rules from "../../../rbac-rules";
import {useSelector} from "react-redux";
import Forbidden from "../../../components/Forbidden";
import useViolationsCount from "../../../api/useViolationsCount";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    }
}));

function ViolationContainer() {

    const params = useParams();
    const classes = useStyles();
    const {isLoading, adViolation} = useViolation(params.violationId);

    const {role} = useSelector(state => state.users.currentUser);
    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.post(`${routes.violations.endpoint}/viewed/${params.violationId}`);
            } catch (error) {
                console.log(error);
            }
        };
        if (check(rules, role, [permissions.violations.view])) {
            fetchData();
        }
    }, []);

    useViolationsCount()

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.violations.list]} noAccess={() => <Forbidden/>}>
                {isLoading || adViolation === null ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <Grid container spacing={3} className={clsx(classes.root, 'nonComplianceReport')}>
                        <Grid item xs={12} md={10} lg={8} xl={6}>
                            <Paper>
                                <ViolationComponent adViolation={adViolation}/>
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </AccessControl>
        </Main>
    );
}

export default ViolationContainer;
