//Email validation
function validateEmail(email) {
  console.log(email)
    if (email.length == 0) return false;
    let regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    for (let i = 0; i < email.length; i++) {
      if (!regex.test(email[i])) {
        return false;
      }
    }
    return true;
  }
  export default validateEmail;