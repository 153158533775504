import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";

import NonComplianceReportComponent from 'components/Reports/NonComplianceReport';
import Main from 'components/Layout/Main';
import AccessControl from "../../../components/RBAC/AccessControl";
import permissions from "../../../permissions";
import Forbidden from "../../../components/Forbidden";
import axios from "axios";
import routes from "../../../routes";
import {handleError} from "../../../errors";
import LinearProgress from "@material-ui/core/LinearProgress";


function NonComplianceReportContainer(props) {

    const [report, setReport] = useState(null);
    const [isLoading, setLoadingReport] = useState(true);
    let location = useLocation();
    let params = useParams();
    useEffect(
        () => {
            setLoadingReport(true);
            axios.get(`${routes.reports.endpoint}${params[0]}${location.search}`,)
                .then(response => {
                    setReport(response.data);
                    setLoadingReport(false);
                }).catch(error => {
                handleError(error);
                setLoadingReport(false);
            });

        },
        []
    );

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.reports.generate]} noAccess={() => <Forbidden/>}>
                {isLoading ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <NonComplianceReportComponent report={report} queryString={location.search} {...props} />
                )}
            </AccessControl>
        </Main>
    );
}

export default NonComplianceReportContainer;