import React, { useState, useEffect } from "react";
import axios from "axios";
import routes from "routes";
import WeeklyAutomationReprotSystem from "./WeeklyAutomationReportSystem";
import WeeklyScheduleListTable from "./WeeklyScheduleListTable";
import EditableWeeklyAutomationReportSystem from "./EditableWeeklyAutomationReportSystem";
import { Container } from '@material-ui/core';


{/**This component is used to logical combine auto report, edit report, and list of records   */}
function WeeklyBrandNonComplianceReport(props) {
    const brandNameOptions = [];
    const [editId, setEditId] = useState(null);
    const [editableReportData, setEditableReportData] = useState('');

    const [brandId, setBrandId] = useState('');
    const [brandNameList, setBrandNameList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getReportDataWithID/${editId}`);

                setBrandId(result.data.agencyid)
                setEditableReportData(result.data);
               

            } catch (error) {
                console.log(error);
            }
            
        };

        fetchData();

    }, [editId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getBrandList/${brandId}`);
                for (let i = 0; i < result.data.length; i++) {
                    brandNameOptions.push({ id: result.data[i][0], brandName: result.data[i][1] })
                }
                setBrandNameList(brandNameOptions);

            } catch (error) {
                console.log(error);
            }
            
        };
        fetchData();

    }, [brandId])
    
    const handleEditReport = (rowData) => {
  
        setEditId(rowData.scheduleId);

    }
    const resetEditId = () =>{
        setEditableReportData('')
        setEditId(null);
    }
    return <Container>
        {(editId && editableReportData && brandNameList.length > 0) ? <EditableWeeklyAutomationReportSystem {...props}
            editableReportData={editableReportData}
            setBrandId={setBrandId}
            brandNameList={brandNameList}
            resetEditId={resetEditId}
            
        />
            : <WeeklyAutomationReprotSystem {...props} />

        }

        <WeeklyScheduleListTable {...props}
            handleEditReport={handleEditReport}
        />
       
    </Container>
}

export default WeeklyBrandNonComplianceReport;
