import {combineReducers} from 'redux';
import {toast} from 'react-toastify';

const initialState = {
    report: {
//    TODO: Add query string params
        reportSummary: {
            brandSummaryList: []
        },
        reportDetail: {
            violatingAds: []
        }
    },
    brands: [],
    compliances: [],
    agencies: [],
    currentUser: {},
    violationsCount: 0
};

const report = (state = initialState.report, action) => {
    switch (action.type) {
        case 'SET_REPORT':
            return {...state, report: action.report};
        default:
            return state;
    }
};

const brands = (state = initialState.brands, action) => {
    switch (action.type) {
        case 'SET_BRANDS':
            return {...state, brands: action.brands};
        default:
            return state;
    }
};

const compliances = (state = initialState.compliances, action) => {
    switch (action.type) {
        case 'SET_COMPLIANCES':
            return {...state, compliances: action.compliances};
        default:
            return state;
    }
};

const toasts = (state = initialState, action) => {
    switch (action.type) {
        case 'SUCCESS_TOAST':
            toast.success(action.msg);
            return state;
        case 'ERROR_TOAST':
            toast.error(action.msg);
            return state;
        default:
            return state;
    }
};

const agencies = (state = initialState.agencies, action) => {
    switch (action.type) {
        case 'SET_AGENCIES':
            return {...state, agencies: action.agencies};
        case 'ADD_AGENCY':
            let agencies = state.agencies;
            agencies.push(action.agency);
            return {...state, agencies};
        default:
            return state;

    }
};

const users = (state = initialState.currentUser, action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            return {...state, currentUser: action.user};
        default:
            return state;
    }
};

const violationsCount = (state = initialState.violationsCount, action) => {
    switch (action.type) {
        case 'SET_UNREAD_VIOLATIONS_COUNT':
         
            
            return {...state, violationsCount: action.violationsCount};
        default:
            return state

    }
}

const rootReducer = combineReducers({
    report,
    brands,
    compliances,
    toasts,
    agencies,
    users,
    violationsCount
});

export default rootReducer;
