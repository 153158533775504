import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Typography, TextField, makeStyles, IconButton, FormHelperText} from '@material-ui/core';
import CreateSharpIcon from "@material-ui/icons/CreateSharp";
import {useSelector} from "react-redux";
import AccessControl, {check} from "../../RBAC/AccessControl";
import rules from "../../../rbac-rules";
import permissions from "../../../permissions";

const useStyles = makeStyles((theme) => ({
    iconMarginLeft: {
        marginLeft: theme.spacing(1)
    }
}));

function Description(props) {

    const [isEditing, setEditing] = useState(false);
    const { compliance, updateCompliance, saveCompliance } = props;
    const [description, setDescription] = useState(compliance.description)
    const classes = useStyles();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.update.basic]);

    const submit = () => {
        if (description !== '' && description !== null) {
            setEditing(false);
            if (description !== compliance.description) {
                const updatedCompliance = { ...compliance, description };
                saveCompliance({ compliance: updatedCompliance, msg: 'Description updated' });
                updateCompliance(updatedCompliance);
            }
        }
    };

    const handleChange = (ev) => {
       
        setDescription(ev.target.value);
    };

    const onBlur = (ev) => {
        submit();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    return (
        <>
            <Typography className={props.classes.title} gutterBottom variant='body2'>
                Description
            </Typography>

            {isEditing ? (
                <>
                    <TextField
                        inputProps={{ 'aria-label': 'Pharma Company' }}
                        autoFocus
                        fullWidth
                        value={description === null ? '' : description}
                        name='description'
                        onBlur={onBlur}
                        onChange={handleChange}
                        size='small'
                        onKeyDown={handleKeyDown}
                        error={description === '' || description === null}
                    />
                    {description === '' &&
                        <FormHelperText error={description === ''}>This field is required</FormHelperText>
                    }
                </>
            ) : (
                <Typography
                    {...(canEdit &&
                        {onClick: () => setEditing(true)}
                    )}
                >
                    {description || ''}
                    <AccessControl allowedPermissions={[permissions.compliances.update.basic]}>
                        <IconButton aria-label='Edit' size='small' className={classes.iconMarginLeft} onClick={() => setEditing(true)}>
                            <CreateSharpIcon fontSize='small' color='secondary' />
                        </IconButton>
                    </AccessControl>
                </Typography>
            )}
        </>
    );
}

Description.propTypes = {
    classes: PropTypes.object,
    updateCompliance: PropTypes.func,
    saveCompliance: PropTypes.func
};

export default Description;
