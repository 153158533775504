import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import {
    Card,
    CardContent,
    Grid,
    makeStyles,
    Typography,
    Divider,
    IconButton,
    Button
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import SearchTerms from 'components/Brands/Details/SearchTerms';
import routes from 'routes';
import { useSelector } from 'react-redux';
import { check } from '../../../../RBAC/AccessControl';
import rules from '../../../../../rbac-rules';
import permissions from '../../../../../permissions';
import MaterialUITable from '../../../../Custom/MaterialUITable';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    paperForm: {
        boxShadow: 'none',
        padding: theme.spacing(0)
    },
    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme.palette.primary.dark
    },
    resultbtn: {
        textAlign: 'end'

    }
}));

function SearchTermsTableComponent(props) {

    const classes = useStyles();
    let history = useHistory();
    const { currentUser } = useSelector(state => state.users);
    const [isResultCounts, setIsResultCounts] = useState(false);
    const canEdit = check(rules, currentUser.role, [permissions.brands.update.basic]);

    const termsResultCounts = () =>{
        
        props.handleViewTermsResult();
        setIsResultCounts(true);
    }

    return (
        <div className={classes.root}>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Typography variant='h3'>
                        <IconButton aria-label='Back' edge='start' onClick={() => history.push(`${routes.brands.path}/${props.brand.id}`)}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                        {props.brand.brandName} {props.type.name}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Card variant='outlined'>

                        <CardContent className={classes.cardContent}>
                            <SearchTerms type={props.type} classes={classes} searchTerms={props.searchTerms}
                                saveTerms={props.saveTerms} count={props.count} />
                        </CardContent>

                        <Divider />
                        <CardContent className={classes.resultbtn} >
                            <Button variant='contained' size='small' color='secondary' onClick={() => termsResultCounts()} >
                                View Results
                            </Button>
                        </CardContent>
                        <Divider />
                        {
                            isResultCounts ?
                            <CardContent className={classes.cardContent}>
                            <MaterialUITable
                                data={props.resultCounts}
                                columns={[
                                    { title: 'Search Terms', field: 'term' },
                                    {
                                        title: 'Date Added', field: 'dateCreated',
                                        render: rowData => (format(new Date(rowData.dateCreated), "MMM d, yyyy 'at' h:mmaaaa")),
                                        customFilterAndSearch: (term, rowData) =>
                                            rowData.dateCreated === null ? false : (format(new Date(rowData.dateCreated), "MMM d, yyyy 'at' h:mmaaaa").toLowerCase()).includes(term.toLowerCase())
                                    },


                                    {
                                        title: 'Results',
                                        field: 'countForAllAdsFound',
                                        render: rowData => (rowData.countForAllAdsFound && rowData.countForAllAdsFound.toLocaleString()),
                                        customFilterAndSearch: (term, rowData) =>

                                            term === rowData.countForAllAdsFound.toString()
                                    }
                                ]}
                               
                                {...(canEdit &&
                                {
                                    actions: [
                                        {
                                            icon: () => <span className='tableActionButton'>Remove</span>,
                                            tooltip: `Remove Term`,
                                            onClick: (event, rowData) => {
                                                props.deleteTerm({ term: { type: props.type.type, term: rowData.term, id: rowData.id }, msg: `${rowData.term} removed from ${props.type.name}` })
                                            }
                                        }
                                    ]
                                }
                                )}
                            />
                        </CardContent>
                        :
                        <CardContent className={classes.cardContent}>
                            <MaterialUITable
                                data={props.searchTerms}
                                columns={[
                                    { title: 'Search Terms', field: 'term' },
                                    {
                                        title: 'Date Added', field: 'dateCreated',
                                        render: rowData => (format(new Date(rowData.dateCreated), "MMM d, yyyy 'at' h:mmaaaa")),
                                        customFilterAndSearch: (term, rowData) =>
                                            rowData.dateCreated === null ? false : (format(new Date(rowData.dateCreated), "MMM d, yyyy 'at' h:mmaaaa").toLowerCase()).includes(term.toLowerCase())
                                    },

                                    {
                                        title: 'Results',

                                    }
                                ]}

                                {...(canEdit &&
                                {
                                    actions: [
                                        {
                                            icon: () => <span className='tableActionButton'>Remove</span>,
                                            tooltip: `Remove Term`,
                                            onClick: (event, rowData) => {
                                                props.deleteTerm({ term: { type: props.type.type, term: rowData.term, id: rowData.id }, msg: `${rowData.term} removed from ${props.type.name}` })
                                            }
                                        }
                                    ]
                                }
                                )}

                            />
                        </CardContent>
                        }    
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

SearchTermsTableComponent.propTypes = {
    type: PropTypes.object,
    title: PropTypes.string,
    newterm: PropTypes.object,
    searchTerms: PropTypes.array,
    brand: PropTypes.object,
    saveTerms: PropTypes.func,
    deleteTerm: PropTypes.func,
    count: PropTypes.number
};

export default SearchTermsTableComponent;
