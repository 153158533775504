import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import CreateComplianceForm from 'components/compliances/Create';
import Main from 'components/Layout/Main';
import {successToast, errorToast} from 'actions';
import routes from 'routes';
import AccessControl from 'components/RBAC/AccessControl'; 
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useBrands from 'api/useBrands';

function CreateComplianceContainer(props) {

    let history = useHistory();
    // Load all the brands
    const { isLoading, brands } = useBrands({}, [], true);
    const [saveStatus, setSaveStatus] = useState(true);
    
    const saveCompliance = async (compliance) => {
       if(saveStatus){
        setSaveStatus(false);
        try {
            const result = await axios.post(`${routes.compliances.endpoint}/saveCompliance`, compliance);
            props.successToast(`${compliance.brandName} successfully created!`);
            setSaveStatus(true);
            history.push(`${routes.compliances.path}`);
        } catch (error) {
            console.log(error);
            setSaveStatus(true);
        }
       }
    };

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.brands.list]} noAccess={() => <Forbidden/>}>
                {isLoading ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <CreateComplianceForm brands={brands} saveCompliance={saveCompliance}/>
                )}
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(CreateComplianceContainer);
