import React, { useState, useEffect } from "react";
import { Button, Container, Typography, FormLabel, FormControlLabel, Checkbox, TextField, Grid, Paper, styled } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultipleValueTextInput from 'react-multivalue-text-input';
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
    inputRoot: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
            color: "red"
        },
    },
    repcont: {
        textAlign: 'center',
    },
    repbtn: {
        float: 'left',

    },
    reptitle: {
        display: 'inline-block',
        marginTop: '10px'
    }
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

{/**This component is used to edit the auto generated report. */ }
function EditableWeeklyAutomationReportSystem(props) {
    const classes = useStyles();

    const agencyNameOptions = [];
    for (let i = 0; i < props.agencyName.length; i++) {
        agencyNameOptions.push({ id: props.agencyName[i][0], agency: props.agencyName[i][1] })
    }
    const [btnStatus, setBtnStatus] = useState(false);
    const [reportName, setReportName] = useState(props.editableReportData.reportname);
    const [agencyName, setAgencyName] = useState(props.editableReportData.agencyname);
    const [agencyId, setAgencyId] = useState(props.editableReportData.agencyid);
    const [brandName, setBrandName] = useState(props.editableReportData.brandid);
    const [brandedUrlStatus, setBrandedUrlStatus] = useState(props.editableReportData.brandedUrlStatus); //for NotIn and NotLike
    const [notInSearchTerm, setNotInSearchTerm] = useState(props.editableReportData.brandedUrlStatus == 'option1' && props.editableReportData.brandedUrlStatus != null ? props.editableReportData.searchTerm : null);
    const [notLikeSearchTerm, setNotLikeSearchTerm] = useState(props.editableReportData.brandedUrlStatus == 'option2' && props.editableReportData.brandedUrlStatus != null ? props.editableReportData.searchTerm : null);
    const [notInAdHostList, setNotInAdHostList] = useState((props.editableReportData.brandedUrlStatus == 'option1' && props.editableReportData.hijackAdHostList.length > 0) ? props.editableReportData.hijackAdHostList.split(",") : []);
    const [notLikeAdHostList, setNotLikeAdHostList] = useState((props.editableReportData.brandedUrlStatus == 'option2' && props.editableReportData.hijackAdHostList.length > 0) ? props.editableReportData.hijackAdHostList.split(",") : []);
    const [brandedUrlList, setBrandedUrlList] = useState(props.editableReportData.brandedUrlList != null ? props.editableReportData.brandedUrlList.split(",") : []);
    const [brandedUrlValue, setBrandedUrlValue] = useState(props.editableReportData.brandedUrlValue);
    const [notIn, setNotIn] = useState(props.editableReportData.brandedUrlStatus == 'option1' ? true : false);
    const [notLike, setNotLike] = useState(props.editableReportData.brandedUrlStatus == 'option2' ? true : false);
    const [isReportNameEmpty, setIsReportNameEmpty] = useState(false);
    const [isAgencyNameEmpty, setIsAgencyNameEmpty] = useState(false);
    const [isBrandNameEmpty, setIsBrandNameEmpty] = useState(false);
    const [isNotInSearchTermEmpty, setIsNotInSearchTermEmpty] = useState(false);
    const [isNotLikeSearchTermEmpty, setIsNotLikeSearchTermEmpty] = useState(false);
    const [isNotInAdHostListEmpty, setIsNotInAdHostListEmpty] = useState(false);
    const [isNotLikeAdHostListEmpty, setIsNotLikeAdHostListEmpty] = useState(false);
    const [isBrandedUrlListEmpty, setIsBrandedUrlListEmpty] = useState(false);
    const [isBrandedUrlvalueEmpty, setIsBrandedUrlvalueEmpty] = useState(false);

    const handleAgencyName = (event, value, reason, details) => {
        setIsAgencyNameEmpty(false);
        if (value !== null) {
            setAgencyName(value.agency);
            setAgencyId(value.id);
            props.setBrandId(value.id)

        } else {
            setAgencyName('');
            setAgencyId('');
            props.setBrandId('')
        }

    }
    const handleBrandName = (event, value, reason, details) => {
        setIsBrandNameEmpty(false)
        if (value !== null) {
            setBrandName(value.id);

        } else {
            setBrandName('');
        }

    }

    const handleNotIn = (event) => {

        setNotIn(!notIn);
        setNotLike(false);

        setIsNotInSearchTermEmpty(false);
        setIsNotInAdHostListEmpty(false);
        setIsBrandedUrlListEmpty(false);
        setIsBrandedUrlvalueEmpty(false)
        if (event.target.checked) {
            setBrandedUrlStatus(event.target.value);
        } else {
            setBrandedUrlStatus(null);
        }
    }
    const handleNotLike = (event) => {

        setNotLike(!notLike);
        setNotIn(false);

        setIsNotLikeSearchTermEmpty(false);
        setIsNotLikeAdHostListEmpty(false)
        if (event.target.checked) {
            setBrandedUrlStatus(event.target.value);
        } else {
            setBrandedUrlStatus(null);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleReportSubmit = async (event) => {
        setBtnStatus(true);
        const report = {
            "reportType": props.report,
            "adhostTemp": [],
            "createdAt": props.editableReportData.createdAt,
            "id": props.editableReportData.id,
            "reportname": reportName,
            "agencyname": agencyName,
            "agencyid": agencyId,
            "brandid": brandName,
            "adprovider": [],
            "adhost": [],
            "adbrandedurl": [],
            "brandedUrlStatus": brandedUrlStatus,
            "searchTerm": null,
            "hijackAdHostList": [],
            "brandedUrlList": brandedUrlList,
            "brandedUrlValue": brandedUrlValue
        }

        if ((notIn == false) && (notLike == false)) {
            if ((reportName != '' && agencyName != '' && brandName != '')) {
                await axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    url: `${routes.scheduleReport.endpoint}/saveQueryParam`,
                    data: JSON.stringify(report)
                }).then((response) => {
                    if (response.status == 200) {
                        notify();
                        setReportName('');
                        setAgencyName('');
                        setBrandName('');
                        setBtnStatus(false);
                        props.resetEditId();
                        props.triggerRefresh();
                    }

                })
                    .catch(error => {
                        console.log(error);
                        setBtnStatus(false);
                    })
            } else {
                if (reportName == '') { setIsReportNameEmpty(true) }
                if (agencyName == '') { setIsAgencyNameEmpty(true) }
                if (brandName == '') { setIsBrandNameEmpty(true) }
                setBtnStatus(false);
                return;
            }
        } else if ((notIn == true) && (notLike == false)) {
            report.searchTerm = notInSearchTerm;
            report.hijackAdHostList = notInAdHostList;
            report.brandedUrlValue = null;
            if (reportName != '' && agencyName != '' && brandName != '' && notInSearchTerm != null && notInSearchTerm != '' && notInAdHostList.length != 0 && brandedUrlList.length != 0) {
                await axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    url: `${routes.scheduleReport.endpoint}/saveQueryParam`,
                    data: JSON.stringify(report)
                }).then((response) => {
                    if (response.status == 200) {
                        notify();
                        setReportName('');
                        setAgencyName('');
                        setBrandName('');
                        setNotInSearchTerm(null);
                        setNotInAdHostList([]);
                        setBrandedUrlList([]);
                        setBtnStatus(false);
                        props.resetEditId();
                        props.triggerRefresh();
                    }

                })
                    .catch(error => {
                        console.log(error);
                        setBtnStatus(false);
                    })
            } else {
                if (reportName == '') { setIsReportNameEmpty(true) }
                if (agencyName == '') { setIsAgencyNameEmpty(true) }
                if (brandName == '') { setIsBrandNameEmpty(true) }
                if (notInSearchTerm == null || notInSearchTerm == '') { setIsNotInSearchTermEmpty(true) }
                if (notInAdHostList.length == 0) { setIsNotInAdHostListEmpty(true) }
                if (brandedUrlList.length == 0) { setIsBrandedUrlListEmpty(true) }
                setBtnStatus(false);
                return;
            }

        } else if ((notIn == false) && (notLike == true)) {
            report.searchTerm = notLikeSearchTerm;
            report.hijackAdHostList = notLikeAdHostList;
            report.brandedUrlList = [];
            if (reportName != '' && agencyName != '' && brandName != '' && notLikeSearchTerm != null && notLikeSearchTerm != '' && notLikeAdHostList.length != 0 && brandedUrlValue != null) {
                await axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    url: `${routes.scheduleReport.endpoint}/saveQueryParam`,
                    data: JSON.stringify(report)
                }).then((response) => {
                    if (response.status == 200) {
                        notify();
                        setReportName('');
                        setAgencyName('');
                        setBrandName('');
                        setNotLikeSearchTerm(null);
                        setNotLikeAdHostList([]);
                        setBrandedUrlValue(null);
                        setBtnStatus(false);
                        props.resetEditId(null);
                        props.triggerRefresh();
                    }

                })
                    .catch(error => {
                        console.log(error);
                        setBtnStatus(false);
                    })
            } else {
                if (reportName == '') { setIsReportNameEmpty(true) }
                if (agencyName == '') { setIsAgencyNameEmpty(true) }
                if (brandName == '') { setIsBrandNameEmpty(true) }
                if (notLikeSearchTerm == null || notLikeSearchTerm == '') { setIsNotLikeSearchTermEmpty(true) }
                if (notLikeAdHostList.length == 0) { setIsNotLikeAdHostListEmpty(true) }
                if (brandedUrlValue == null || brandedUrlValue == '') { setIsBrandedUrlvalueEmpty(true) }
                setBtnStatus(false);
                return;
            }
        }

    }
    const notify = () => toast.success('Successfully Saved', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });;

    return <>
        <div>
            <Container className={classes.repcont} >
                <Typography variant="h3" gutterBottom component="div" className={classes.reptitle} >
                    WELCOME TO AUTOMATION REPORT SYSTEM
                </Typography>
            </Container>
            <Grid container spacing={4} className="mb-2" >
                <Grid item xs={4}>
                    <TextField
                        value={reportName}
                        defaultValue={reportName}
                        id="standard-basic"
                        label="Report Name*"
                        variant="standard"
                        fullWidth="true"
                        error={isReportNameEmpty}
                        onChange={(event) => { setReportName(event.target.value); setIsReportNameEmpty(false) }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        defaultValue={(agencyNameOptions.filter((value) => value.agency == props.editableReportData.agencyname))[0]}
                        id="tags-standard"
                        options={agencyNameOptions}
                        getOptionLabel={(option) => option.agency}

                        onChange={handleAgencyName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Agency Name*"
                                placeholder="Select"
                                error={isAgencyNameEmpty}
                            />
                        )}
                    />

                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        defaultValue={(props.brandNameList.filter((value) => value.id == props.editableReportData.brandid))[0]}
                        id="tags-standard"
                        options={props.brandNameList}
                        getOptionLabel={(option) => option.brandName}
                        onChange={handleBrandName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Brand Name*"
                                placeholder="Select"
                                error={isBrandNameEmpty}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormLabel component="legend">Assign responsibility</FormLabel>
                    <FormControlLabel control={<Checkbox checked={notIn} value="option1" onChange={(event) => handleNotIn(event)} />} label="NOT IN" />
                    <FormControlLabel control={<Checkbox checked={notLike} value="option2" onChange={(event) => handleNotLike(event)} />} label="NOT LIKE" />

                </Grid>

                {notIn ? <>
                    <Grid item xs={4}>
                        <TextField
                            value={notInSearchTerm}
                            defaultValue={notInSearchTerm}
                            id="standard-basic"
                            label="Search Term*"
                            variant="standard"
                            fullWidth="true"
                            error={notIn ? isNotInSearchTermEmpty : null}
                            onChange={(event) => { setNotInSearchTerm(event.target.value); setIsNotInSearchTermEmpty(false); }}
                        />
                    </Grid>
                    <Grid item xs={4} className="multivaluecustominput">
                        <MultipleValueTextInput
                            values={notInAdHostList}
                            onItemAdded={(item, allItems) => { setNotInAdHostList([...notInAdHostList, item]); setIsNotInAdHostListEmpty(false) }}
                            onItemDeleted={(item, allItems) => setNotInAdHostList(notInAdHostList.filter((value) => value != item))}
                            label="Brand Hijack Ad Host List*"
                            name="item-input"
                            placeholder="Ad Host List."
                            style={notIn && isNotInAdHostListEmpty ? { borderColor: "red" } : null}
                        />

                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={4} className="multivaluecustominput">
                        <MultipleValueTextInput
                            values={brandedUrlList}
                            onItemAdded={(item, allItems) => { setBrandedUrlList([...brandedUrlList, item]); setIsBrandedUrlListEmpty(false) }}
                            onItemDeleted={(item, allItems) => setBrandedUrlList(brandedUrlList.filter((value) => value != item))}
                            label="Brand Hijack Branded URL List*"
                            name="item-input"
                            placeholder="Branded URL List."
                            style={notIn && isBrandedUrlListEmpty ? { borderColor: "red" } : null}
                        />

                    </Grid>
                </> : null}

                {notLike ? <><Grid item xs={4}>
                    <TextField
                        value={notLikeSearchTerm}
                        defaultValue={notLikeSearchTerm}
                        id="standard-basic"
                        label="Search Term*"
                        variant="standard"
                        fullWidth="true"
                        error={notLike ? isNotLikeSearchTermEmpty : null}
                        onChange={(event) => { setNotLikeSearchTerm(event.target.value); setIsNotLikeSearchTermEmpty(false) }}
                    />
                </Grid>
                    <Grid item xs={4} className="multivaluecustominput">
                        <MultipleValueTextInput
                            values={notLikeAdHostList}
                            onItemAdded={(item, allItems) => { setNotLikeAdHostList([...notLikeAdHostList, item]); setIsNotLikeAdHostListEmpty(false) }}
                            onItemDeleted={(item, allItems) => setNotLikeAdHostList(notLikeAdHostList.filter((value) => value != item))}
                            label="Brand Hijack Ad Host List*"
                            name="item-input"
                            placeholder="Ad Host List."
                            style={notLike && isNotLikeAdHostListEmpty ? { borderColor: "red" } : null}
                        />

                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={brandedUrlValue}
                            defaultValue={brandedUrlValue}
                            id="standard-basic"
                            label="Brand Hijack Branded URL List Value*"
                            variant="standard"
                            fullWidth="true"
                            placeholder="Branded URL Value."
                            error={notLike ? isBrandedUrlvalueEmpty : null}
                            onChange={(event) => { setBrandedUrlValue(event.target.value); setIsBrandedUrlvalueEmpty(false) }}
                        />
                    </Grid>
                </> : null}
                <Grid item xs={12}>
                    <div className="text-right1 bt-1 pt-2">
                        <Button
                            disabled={btnStatus}
                            onClick={handleReportSubmit}
                            variant="contained"
                            color='secondary'
                            type='submit'
                        >Submit</Button>
                        <Button
                            onClick={()=>props.resetEditId()}
                            variant="contained"
                            type='submit'
                            style={{ marginLeft: "20px" }}
                        >Cancel</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    </>
}

export default EditableWeeklyAutomationReportSystem;

