import React, { useState, useEffect } from 'react';
import axios from "axios";
import routes from "routes";
import { toast } from "react-toastify";
import dateConverter from 'utils/dateConverter';
import MaterialTable from 'material-table'
import { Button } from '@material-ui/core';
import DailyBrandScheduleTableColumn from './DailyBrandScheduleTableColumn'
import PreviewDailyBrandScheduledQuery from './PreviewDailyBrandScheduledQuery';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    marginLeft: '15%'
  },
}));

{/** This component is the data table for daily brand report */ }

function GenerateScheduledQuery(genRepName, generateData) {

  var blob = new Blob(
    [s2ab(atob(generateData))],
    {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
    });

  var a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = genRepName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);



}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i)
    view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

function DailyBrandScheduleListTable(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  const [previewId, setPreviewId] = useState(null);
  const [previewData, setPreviewData] = useState([]);

  const [generateId, setGenerateId] = useState(null);

  const [genRepName, setGenRepName] = useState('');

  const [openSchedule, setOpenSchedule] = useState(false);
  const handleOpenSchedule = () => setOpenSchedule(true);
  const handleCloseSchedule = () => {
    setOpenSchedule(false);
    setPreviewId(null);
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  let data = [];
  for (let i = 0; i < props.dailyBrandReportData.length; i++) {
    data.push({
      scheduleId: props.dailyBrandReportData[i][0],
      reportname: props.dailyBrandReportData[i][1],
      advertiser: props.dailyBrandReportData[i][2],
      advertiserTemp: props.dailyBrandReportData[i][4],
      createddate: dateConverter(props.dailyBrandReportData[i][3]),
    })
  }
  let columns = [
    { title: 'Report Name', field: 'reportname' },
    { title: 'Advertiser', field: 'advertiser' },
    { title: 'Advertiser Temporary', field: 'advertiserTemp' },
    { title: 'Schedule', field: 'scheduleId', render: rowData => <DailyBrandScheduleTableColumn rowData={rowData} {...props} /> },
    { title: 'Created Date', field: 'createddate' },

  ]

  useEffect(() => {

    const fetchData = async () => {
      try {
        const result = await axios.get(`${routes.scheduleReport.endpoint}/showQueryById/${previewId}`);
        console.log("editsch", result)
        if (result.status == "200") {
          setPreviewData(result.data);
        }


      } catch (error) {
        console.log(error);
      }

    };
    if (previewId != null) {
      fetchData();
    }
  }, [previewId])

  useEffect(() => {

    const fetchData = async () => {
      handleToggle();
      try {

        const result = await axios.get(`${routes.scheduleReport.endpoint}/getGenerateReport/${generateId}`);

        GenerateScheduledQuery(genRepName, result.data);
        setGenerateId(null);
        handleClose();
      } catch (error) {
        handleClose();
        console.log(error);
      }

    };
    if (generateId != null) {
      fetchData();
    }
  }, [generateId])

  const previewSchedule = (rowData) => {
    setPreviewData('')
    setPreviewId(rowData.scheduleId);
    handleOpenSchedule();

  }
  const generateSchedule = (rowData) => {

    setGenerateId(rowData.scheduleId);
    setGenRepName(rowData.reportname + ".xlsx")

  }
  const deleteReport = (rowData) => {


    const deleteReport = async () => {
      try {

        const result = await axios.get(`${routes.scheduleReport.endpoint}/QueryParamDeleteById/${rowData.scheduleId}`);
        if (result.data === "success") {
          notify();
          props.triggerRefresh();
        }


      } catch (error) {
        console.log(error);
      }

    };
    deleteReport();
  }
  const notify = () => toast.warning('Successfully Deleted', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  return (
    <>
      <MaterialTable
        title="SCHEDULE LIST"
        columns={columns}
        data={data}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit Report',
            onClick: (event, rowData) => props.editDailyReport(rowData)
          },
          {

            icon: 'delete',
            tooltip: 'Delete Report',
            onClick: (event, rowData) => deleteReport(rowData)
          },
          {

            icon: 'preview',
            tooltip: 'View Query',
            onClick: (event, rowData) => previewSchedule(rowData)
          },

          {

            icon: () => <Button variant="contained" color='secondary' type='submit'  >Generate</Button>,
            tooltip: 'Generate Report',
            onClick: (event, rowData) => generateSchedule(rowData)
          },

        ]}
        options={{
          actionsCellStyle: {
            width: '350px',
            padding: '0px 40px'
          },
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#3fb44f',
            color: '#FFF'
          },
          rowStyle: (rowData, index) => {
            return index % 2 === 0 ? {
              backgroundColor: '#fcfcfc',
              border: '2px solid #d6d6f5',
              wordBreak: 'break-all',
            } : {
              backgroundColor: '#faf0f6',
              border: '2px solid #d6d6f5',
              wordBreak: 'break-all',
            }
          }

        }}
      />
      <PreviewDailyBrandScheduledQuery
        openSchedule={openSchedule}
        handleCloseSchedule={handleCloseSchedule}
        previewData={previewData}
      />
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default DailyBrandScheduleListTable;

