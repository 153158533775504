import React, { useState, useEffect } from 'react';
//import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import CreateComplianceForm from 'components/compliances/Create';
import Main from 'components/Layout/Main';
import { successToast, errorToast } from 'actions';
import routes from 'routes';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
//import useBrands from 'api/useBrands';
import CreateSummary from 'components/summaries/Create'

function CreateSummaryContainer(props) {

    const [summary, setSummary] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);


    const getSummary = async (data) => {
        console.log("data",data)
        try {
            const result = await axios.post(`${routes.summary.endpoint}/getCount`, data);
            // setIsLoading(true);
            setSummary(result.data);
            props.successToast(`Get total Counts successfully!`);

        } catch (error) {
            props.errorToast(`Error occured while fetchig Total Counts!`);
            console.log(error);
        }
    }

    const saveSummary = async (data) => {
        try {
            const result = await axios.post(`${routes.summary.endpoint}/saveCount`, data);
            props.successToast(`Data saved successfully!`);
        } catch (error) {
            props.errorToast(`Error occured Saving Counts!`);
            console.log(error);
        }
    }

    const deleteSummary = async (data) => {
        try {
            const result = await axios.post(`${routes.summary.endpoint}/deleteData`, data);
            props.successToast(`Data deleted successfully!`);
        } catch (error) {
            props.errorToast(`Error occured deleting Counts!`);
            console.log(error);
        }
    }

    return (
        <Main>

            <AccessControl allowedPermissions={[permissions.brands.list]} noAccess={() => <Forbidden />}>
                {/* {isLoading ? (
                    <LinearProgress color='secondary' />
                ) : ( */}

                    <CreateSummary summary={summary} getSummary={getSummary} saveSummary={saveSummary} deleteSummary={deleteSummary} />

                {/* )} */}
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(CreateSummaryContainer);
