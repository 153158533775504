import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";

// import TermsTableComponent from "components/Brands/Details/SearchTerms/List";
import SearchTermsTableComponent from "components/Brands/Details/SearchTerms/List/SearchTermsTableComponent.js";
import searchTerms from "components/Brands/Details/SearchTerms/searchTerms";
import Main from "components/Layout/Main";

import { successToast, errorToast } from "actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import routes from "routes";
import AccessControl from "components/RBAC/AccessControl";
import permissions from "permissions";
import Forbidden from "components/Forbidden";
import useBrand from "api/useBrand";
import useSearchTerms from "api/useSearchTerms";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
function BrandsSearchTermsContainer(props) {

  const [open, setOpen] = React.useState(false);
  const { brandId } = useParams();
  let params = useParams();
  const [terms, setTerms] = useState([]);
  const [terms1, setTerms1] = useState([]);
  const [newTerms, setNewTerms] = useState([]);
  const [resultCounts, setResultCounts] = useState([]);

  // Fetch the brand
  const { brand } = useBrand(params.brandId);

  // Fetch terms for the brand
  const { terms: fetchedTerms } = useSearchTerms(params.brandId, [newTerms]);

  useEffect(() => {

    setTerms(fetchedTerms);
    setTerms1(fetchedTerms?.searchTerms)
    
  });

  useEffect(() => {
    handleViewTerms(brandId);
  }, []);

  // Save terms
  const saveTerms = async ({ terms, msg }) => {
    try {
      await axios.post(
        `${routes.brands.endpoint}/${params.brandId}/terms`,
        terms
      );
      setNewTerms(terms);
      props.successToast(msg);
    } catch (error) {
      console.log(error);
    }
  };

  // Deletes a term
  const deleteTerm = async ({ term, msg }) => {
    
    try {
      await axios.post(
        `${routes.brands.endpoint}/${params.brandId}/terms/delete`,
        term
      );
      setTerms({
        ...terms,
        searchTerms: terms.searchTerms.filter((value) => value.id !== term.id),
      });
      setNewTerms(terms);
      props.successToast(msg);
    } catch (error) {
      console.log(error);
    }
  };
  const handleViewTerms = async (brandId) => {
    try {
      setOpen(true)
      const result = await axios.get(
        `${routes.brands.endpoint}/${brandId}/${`Search Terms`}/termsCounts`
      );

      setTerms1(result.data.data.searchTerms);
      setOpen(false)
    } catch (error) {
      console.log(error);
      setOpen(false)
    }
  };
  // fetch view terms result counts 23/03/2022 @ Aravind
  const handleViewTermsResult = async () => {

    try {
      setOpen(true)
      const result = await axios.get(
        `${routes.brands.endpoint}/${brandId}/resultCounts`
      );
      console.log(result);

      setResultCounts(result.data.data.searchTerms)
      setOpen(false)
    } catch (error) {
      console.log(error);
      setOpen(false)
    }
  };



  return (
    <Main>
      <Backdrop style={{ zIndex: 1000, color: '#fff' }} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AccessControl
        allowedPermissions={[permissions.brands.detail]}
        noAccess={() => <Forbidden />}
      >
        {terms === null || brand === null ? (
          <LinearProgress color="secondary" />
        ) : (
          <SearchTermsTableComponent

            type={searchTerms.searchTerms}
            searchTerms={terms1}
            deleteTerm={deleteTerm}
            brand={brand}
            saveTerms={saveTerms}
            count={terms.searchTermsCount}
            handleViewTermsResult={handleViewTermsResult}
            resultCounts={resultCounts}
          />
        )}
      </AccessControl>
    </Main>
  );
}

const mapDispatchToProps = (dispatch) => ({
  successToast: (msg) => dispatch(successToast(msg)),
  errorToast: (msg) => dispatch(errorToast(msg)),
});

export default connect(null, mapDispatchToProps)(BrandsSearchTermsContainer);
