import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => {
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height: "100vh", backgroundColor: "rgba(0, 0, 0, 0.5)", color: "black"}}>
        <CircularProgress color="inherit" />
    </div>
  )
}

export default Loader
