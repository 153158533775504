import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Button} from '@material-ui/core';
import MaterialTable, {MTableBodyRow, MTableCell} from 'material-table';
import {ArrowUpward as ArrowUpwardIcon, FilterList as FilterListIcon} from '@material-ui/icons';
import palette from '../../../../theme/palette';
const useStyles = makeStyles((theme) => ({
    tableRow: {
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableCell: {
        fontWeight: 'inherit'
    }
}));

function ComplianceTable({ data, columns, rowStyle, deleteCompliance, scrapCompliance, ...rest }) {


    const classes = useStyles();
    const height = window.innerHeight - 300;
   
    return (
        <MaterialTable
            key={data.length}
            options={{
                search: false,
                filtering: true,
                sorting: true,
                pagination: true,
                maxBodyHeight: height,
                toolbar: false,
                headerStyle: {
                    color: palette.primary.dark,
                    fontWeight: '900',
                },
                emptyRowsWhenPaging: false,
                pageSize: data.length,
                actionsColumnIndex: -1,
                rowStyle: rowStyle
            }}
            style={{borderRadius: 0, boxShadow: 'none'}}
            components={{
                Pagination: props => (<></>),
                Row: props => (<MTableBodyRow {...props} className={classes.tableRow}/>),
                Cell: props => (<MTableCell {...props} className={classes.tableCell}/>)
            }}
            data={data}
            icons={{
                Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref}/>),
                SortArrow: forwardRef((props, ref) => <ArrowUpwardIcon {...props} ref={ref}/>),
            }}
            columns={columns}
            actions={[
                {
                    icon: (props) => <Button variant="contained" color='secondary' type='submit'  >Scraper</Button>,
                    tooltip: 'Scrap Compliance',
                    onClick: (event, rowData) => scrapCompliance(rowData)
                  },
                {

                    icon: 'delete',
                    tooltip: 'Delete Compliance',
                    onClick: (event, rowData) => deleteCompliance(rowData)
                  },
                
            ]}
            {...rest}
        />
    );
}

ComplianceTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowStyle: PropTypes.func
};

export default ComplianceTable;
