import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import ReportSearchForm from 'components/Reports/SearchForm';
import Main from 'components/Layout/Main';
import qs from 'qs';

import { setReport } from 'actions';
import axios from 'axios';
import routes from 'routes';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useBrands from 'api/useBrands';
import useUsers from 'api/useUsers';
import useRules from "../../../api/useRules";
import useAdProviders from "../../../api/useAdProviders";
import useBrandUrls from "../../../api/useBrandUrls";
import useSearchTerm from "../../../api/useSearchTerm";

import useAgencyName from "../../../api/useAgencyName";

import LinearProgress from "@material-ui/core/LinearProgress";
import useBrandUrl from 'api/useBrandUrl';
import useBrandedUrl from 'api/useBrandedUrl';
import DirectComplianceReprot from './AutoComplianceReport/DirectComplianceReport';
import BrandNonComplianceReport from './AutoReportSys/BrandNonComplianceReport';
import DailyBrandNonComplianceReport from './AutoBrandSys/DailyBrandNonComplianceReport';
import WeeklyBrandNonComplianceReport from './AutoWeeklySys/WeeklyBrandNonComplianceReport';


function ReportsSearchContainer(props) {
    const [refresh, setRefresh] = useState(true);
    const [report, setReport] = useState('brandcompliance');
    const [loadingReport, setLoadingReport] = useState(false);

    const { isLoading: brandLoading, brands } = useBrands({}, [], true);
    const { isLoading: userLoading, users } = useUsers({}, [], true);
    const { isLoading: ruleLoading, rules } = useRules();
    const { isLoading: adProviderLoading, adProvider } = useAdProviders();
    const { isLoading: brandUrlsLoading, brandUrls } = useBrandUrls();
    const { isLoading: searchTermLoading, searchTerm } = useSearchTerm();

    const { isLoading: agencyNameLoading, agencyName } = useAgencyName();
    const { isLoading: brandUrlLoading, brandUrl } = useBrandUrl();
    const { isLoading: brandedUrlLoading, brandedUrl } = useBrandedUrl();

    const [reportData, setReportData] = useState([]);
    const [dailyBrandReportData, setDailyBrandReportData] = useState([]);
    const [weeklyReportData, setWeeklyReportData] = useState([]);

    const handleReportSelect = (value) => {
        setReport(value)

    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getReportData`);

                setReportData(result.data);

            } catch (error) {
                console.log(error);
            }

        };
        fetchData();
    }, [refresh])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getDailyBrandReport`);
                setDailyBrandReportData(result.data);

            } catch (error) {
                console.log(error);
            }

        };
        fetchData();
    }, [refresh])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.scheduleReport.endpoint}/getWeeklyFixedReportData`);
                setWeeklyReportData(result.data);
               
            } catch (error) {
                console.log(error);
            }

        };
        fetchData();
    }, [refresh])

    const generateReport = (params) => {
        let queryString = qs.stringify(params, { arrayFormat: 'repeat' })
        console.log("queryString", params)
        let url = `${window.location.origin}${routes.reports.path}/${params.report}?${queryString} `
        console.log("url", url)
        let win = window.open(url, '_blank');
        win.focus();
    };


    const triggerRefresh = () => {
        setReportData([]);
        setDailyBrandReportData([]);
        setWeeklyReportData([]);
        setRefresh(!refresh);

    }
    return (
        <Main>
            {report == 'brandcompliance' &&
                <AccessControl allowedPermissions={[permissions.reports.generate]} noAccess={() => <Forbidden />}>
                    {brandLoading || userLoading || ruleLoading || adProviderLoading || brandUrlsLoading || searchTermLoading ? (
                        <LinearProgress color="secondary" thickness={4} />
                    ) : (
                        <ReportSearchForm
                            report={report}

                            handleReportSelect={handleReportSelect}
                            generateReport={generateReport}
                            loadingReport={loadingReport}
                            users={users}
                            brands={brands}
                            rules={rules}
                            adProvider={adProvider}
                            brandUrls={brandUrls}
                            searchTerm={searchTerm}
                            agencyName={agencyName}
                            brandUrl={brandUrl}
                            brandedUrl={brandedUrl}
                            triggerRefresh={triggerRefresh}

                        />
                    )}
                </AccessControl>
            }

            {report == 'brandNonCompliance' &&
                <BrandNonComplianceReport
                    handleReportSelect={handleReportSelect}
                    refresh={refresh}
                    triggerRefresh={triggerRefresh}
                    report={report}
                    setReport={setReport}
                    generateReport={generateReport}
                    loadingReport={loadingReport}
                    users={users}
                    brands={brands}
                    rules={rules}
                    adProvider={adProvider}
                    brandUrls={brandUrls}
                    searchTerm={searchTerm}
                    agencyName={agencyName}
                    brandUrl={brandUrl}
                    brandedUrl={brandedUrl}
                    reportData={reportData}

                />}
            {report == 'dailyBrandNonCompliance' &&
                <DailyBrandNonComplianceReport
                    report={report}
                    brandUrl={brandUrl}
                    dailyBrandReportData={dailyBrandReportData}
                    refresh={refresh}
                    triggerRefresh={triggerRefresh}
                    handleReportSelect={handleReportSelect}
                />}
            {report == 'weeklyFixedCompliance' &&
                <WeeklyBrandNonComplianceReport
                    handleReportSelect={handleReportSelect}
                    refresh={refresh}
                    triggerRefresh={triggerRefresh}
                    report={report}
                    agencyName={agencyName}
                    brandUrl={brandUrl}
                    brandedUrl={brandedUrl}
                    weeklyReportData={weeklyReportData}

                />}
                 {report == 'directCompliance' &&
                <DirectComplianceReprot
                    handleReportSelect={handleReportSelect}
                    refresh={refresh}
                    triggerRefresh={triggerRefresh}
                    // report={report}
                    // setReport={setReport}
                    // generateReport={generateReport}
                    // loadingReport={loadingReport}
                    // users={users}
                    brands={brands}
                    // rules={rules}
                    // adProvider={adProvider}
                    // brandUrls={brandUrls}
                    // searchTerm={searchTerm}
                    // agencyName={agencyName}
                    // brandUrl={brandUrl}
                    // brandedUrl={brandedUrl}
                    // reportData={reportData}

                />}
        </Main>


    );
}

ReportsSearchContainer.propTypes = {
    setReport: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    setReport: report => dispatch(setReport(report))
});

export default connect(null, mapDispatchToProps)(ReportsSearchContainer);
