import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import routes from 'routes';
import permissions from 'permissions';
import rules from 'rbac-rules';
import {check} from 'components/RBAC/AccessControl';

export default (complianceId) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [compliance, setCompliance] = useState(null);
    const {role} = useSelector(state => state.users.currentUser);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${routes.compliances.endpoint}/getComplianceDataWithID/${complianceId}`);
               
                setCompliance(result.data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (check(rules, role, [permissions.compliances.list, permissions.compliances.detail, permissions.compliances.update.basic, permissions.compliances.update.full])) {
            setIsLoading(true);
            fetchData();
        }
    }, []);

    return {isLoading, compliance};
}
