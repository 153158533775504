import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import ComplianceDetailsComponent from 'components/compliances/Details';
import Main from 'components/Layout/Main';

import { successToast, errorToast } from 'actions';
import routes from 'routes';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useBrands from 'api/useBrands';

import useCompliance from 'api/useCompliance';

function ComplianceDetailsContainer(props) {
    
    let params = useParams();
    const { isLoading, brands } = useBrands({}, [], true);
    const [compliance, setCompliance] = useState(null);
    const [newTerms, setNewTerms] = useState([]);
    const { compliance: fetchedCompliance } = useCompliance(params.complianceId);

    useEffect(() => {
        // Set the compliance
        setCompliance(fetchedCompliance);
    }, [fetchedCompliance]);

    const saveCompliance = async({ compliance, msg }) => {
        
        try {
            const updatedCompliance = {...compliance };
          
            if (updatedCompliance.brandName === null || updatedCompliance.brandName === '') {
                props.errorToast('Brand Name Cannot be Empty');
            } 
            else if (updatedCompliance.urls === null || updatedCompliance.urls === '') {
                props.errorToast('WebSite Domain Urls Company Cannot be Empty');
            }  
            else if (updatedCompliance.description === null || updatedCompliance.description === '') {
                props.errorToast('Description Cannot be Empty');
            } 
            else {
              
                const response = await axios.post(`${routes.compliances.endpoint}/updateComplianceData`, updatedCompliance);
                
                props.successToast(msg);
            }
        } catch (error) {
            console.log(error);
            props.errorToast('Error Occurred');
        }
    };

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.compliances.detail]} noAccess={() => <Forbidden/>}>
            {(compliance !== null) && !isLoading? (
                <ComplianceDetailsComponent compliance={compliance} updateCompliance={setCompliance} saveCompliance={saveCompliance} brands={brands} />
            ) : (
                <LinearProgress color='secondary' />
            )}
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(ComplianceDetailsContainer);
