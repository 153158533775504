import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import TermsTableComponent from 'components/Brands/Details/SearchTerms/List';
import searchTerms from 'components/Brands/Details/SearchTerms/searchTerms';
import Main from 'components/Layout/Main';

import {successToast, errorToast} from 'actions';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import routes from 'routes';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useBrand from 'api/useBrand';
import useSearchTerms from 'api/useSearchTerms';

function BrandsHeadlineTermsContainer(props) {
 const { brandId } = useParams();
    let params = useParams();
    const [terms, setTerms] = useState(null);
    const [newTerms, setNewTerms] = useState([]);

    // Fetch the brand
    const { brand } = useBrand(params.brandId);

    // Fetch terms for the brand
    const { terms: fetchedTerms } = useSearchTerms(params.brandId, [newTerms]);
    const [terms1, setTerms1] = useState([]);

  useEffect(() => {
    handleViewTerms(brandId);
  }, []);

    useEffect(() => {
        setTerms(fetchedTerms);
        setTerms1(fetchedTerms?.headlines);
    });

    //Save terms
    const saveTerms = async ({terms, msg}) => {
        // console.log("saveterms", terms, msg)
        try {
            await axios.post(`${routes.brands.endpoint}/${params.brandId}/terms`, terms);
            setNewTerms(terms);
            props.successToast(msg);
        } catch (error) {
            console.log(error);
        }
    };
const handleViewTerms = async (brandId) => {
    try {
      const result = await axios.get(
        `${routes.brands.endpoint}/${brandId}/${`Headline Terms`}/termsCounts`
      );
      setTerms1(result.data.data.headlines);
    } catch (error) {
      console.log(error);
    }
  };
    // Deletes a term
    const deleteTerm = async ({term, msg}) => {
        console.log("test removed-->>", term, msg)
        try {
            const response = await axios.post(`${routes.brands.endpoint}/${params.brandId}/terms/delete`, term);

            // this code was not performing anything

            // setTerms({
            //     ...terms,
            //     headlineTerms: terms.headlineTerms.filter(
            //         value => {
            //             value.id !== term.id
            //         }
            //     )
            // });

            setNewTerms(term);
            props.successToast(msg);
        } catch (error) {
            props.errorToast(msg);
           // props.errorToast("Unable to remove prescription from headline Terms!");
            // console.log("removed catch", error)
            console.log(error);
        }
    };

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.brands.detail]} noAccess={() => <Forbidden/>}>
                {terms === null || brand === null ? (
                    <LinearProgress color='secondary'/>
                ) : (
                    <TermsTableComponent type={searchTerms.headlineTerms} searchTerms={terms1}
                                         brand={brand} saveTerms={saveTerms} deleteTerm={deleteTerm}
                                         count={terms.headlineTermsCount}/>
                )}
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(BrandsHeadlineTermsContainer);















// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { connect } from 'react-redux';
// import axios from 'axios';

// import LinearProgress from '@material-ui/core/LinearProgress';
// import TermsTableComponent from 'components/Brands/Details/SearchTerms/List';
// import searchTerms from 'components/Brands/Details/SearchTerms/searchTerms';
// import Main from 'components/Layout/Main';

// import { successToast, errorToast } from 'actions';
// import routes from 'routes';
// import AccessControl from 'components/RBAC/AccessControl';
// import permissions from 'permissions';
// import Forbidden from 'components/Forbidden';
// import useBrand from 'api/useBrand';
// import useSearchTerms from 'api/useSearchTerms';
// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';


// function BrandsHeadlineTermsContainer(props) {
//         console.log(props,"Head lines console")
//   const [open, setOpen] = React.useState(false);
//   let params = useParams();
//   const [newTerms, setNewTerms] = useState([]);
//   const [terms, setTerms] = useState(null);
//   const [terms1, setTerms1] = useState(null);
//   console.log(terms, 'terms>>>>>>>>>>>')
//   const { brandId } = useParams();
//   // Fetch the brand
//   const { brand } = useBrand(params.brandId);

//   // Fetch terms for the brand
//   const { terms: fetchedTerms } = useSearchTerms(params.brandId, [newTerms]);


//   useEffect(() => {
//     setTerms(fetchedTerms);
//   });
//   useEffect(() => {
//     handleViewTerms(brandId);
//   }, []);

//   // Save terms
//   const saveTerms = async ({ terms, msg }) => {
//     try {
//       await axios.post(`${routes.brands.endpoint}/${params.brandId}/terms`, terms);
//       setNewTerms(terms);
//       props.successToast(msg);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleViewTerms = async (brandId) => {
//     try {
//       setOpen(true)
//       const result = await axios.get(`${routes.brands.endpoint}/${brandId}/${`Headline Terms`}/termsCounts`);


//       setTerms1(result.data.data.headlineTerms);
//       setOpen(false)
//       // setOpen(false)
//     } catch (error) {
//       console.log(error);
//       setOpen(false)
//     }
//   };
//   // Deletes a term
//   const deleteTerm = async ({ term, msg }) => {
//     try {
//       await axios.post(`${routes.brands.endpoint}/${params.brandId}/terms/delete`, term);
//       let headlineTerms = terms.headlineTerms;
//       setTerms({ ...terms, headlineTerms: headlineTerms.filter(value => value.id !== term.id) });
//       setNewTerms(terms);
//       props.successToast(msg);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <Main>
//       <Backdrop style={{ zIndex: 1000, color: '#fff' }} open={open} >
//         <CircularProgress color="inherit" />
//       </Backdrop>
//       <AccessControl allowedPermissions={[permissions.brands.detail]} noAccess={() => <Forbidden />}>
//         {terms === null || brand === null || terms1 === null? (
//           <LinearProgress color='secondary' />
//         ) : (
//           <TermsTableComponent type={searchTerms.headlineTerms}
//             searchTerms={terms1}
//             deleteTerm={deleteTerm}
//             brand={brand} saveTerms={saveTerms} count={terms.headlinesTermCount} />
//         )}
//       </AccessControl>
//     </Main>
//   );
// }

// const mapDispatchToProps = dispatch => ({
//   successToast: msg => dispatch(successToast(msg)),
//   errorToast: msg => dispatch(errorToast(msg))
// });

// export default connect(null, mapDispatchToProps)(BrandsHeadlineTermsContainer);
