import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, makeStyles, Tab } from '@material-ui/core';
import routes from 'routes';
import AccessControl, { check } from '../../RBAC/AccessControl';
import rules from '../../../rbac-rules';
import { useSelector } from 'react-redux';
import permissions from '../../../permissions';
import MaterialUITable from '../../Custom/MaterialUITable';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    addButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

export default function BrandsTableComponent(props) {

    const { brands } = props;
    const classes = useStyles();
    let history = useHistory();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.detail]);

    const [value, setValue] = React.useState('1');
    const [showRetailData, setShowRetailData] = React.useState([]);
    const [showPharmaData, setShowPharmaData] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //   for the testing purpose only
    // const handleClickMe = async()=>{
    //     try {

    //         const result = await axios.get(`http://127.0.0.1:8080/scraper/getComplianceExtrator`);
    //         // props.successToast(`${compliance.brandName} successfully created!`);

    //         // history.push(`${routes.compliances.path}`);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    useEffect(()=>{
        const filterRetailData=brands.filter((items)=>items.retailersEnabled===true)
        const filterPharmaData=brands.filter((items)=>items.retailersEnabled!==true)
        setShowPharmaData(filterPharmaData)
        setShowRetailData(filterRetailData)
        },[brands])


    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Pharmaceutical Brand" value="1" />
                                <Tab label="Retailers Brand" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Card variant='outlined'>
                                <CardHeader
                                    title={routes.brands.pageTitle}
                                    subheader='The following brands have been configured for your agency. You can add additional brands or edit brands from this screen.'
                                    action={
                                        <AccessControl allowedPermissions={[permissions.brands.create]}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.addButton}
                                                onClick={
                                                    () => history.push(`${routes.brands.path}/new`)
                                                }
                                            >
                                                Add Brand
                                            </Button>
                                        </AccessControl>
                                    }
                                />
                                <Divider />
                                <CardContent className={classes.cardContent}>
                                    <MaterialUITable
                                        data={showPharmaData}
                                        columns={[
                                            { title: 'Brand Name', field: 'brandName' },
                                            { title: 'Pharma Company', field: 'pharmaCompany' },
                                            {
                                                title: 'Search Strategist',
                                                field: 'searchStrategists',
                                                customFilterAndSearch: (term, rowData) => {
                                                    if (rowData.searchStrategists !== undefined) {
                                                        return rowData.searchStrategists.some(
                                                            (strategist) => strategist.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
                                                        )
                                                    }
                                                },
                                                render: rowData => {
                                                    if (rowData.searchStrategists !== undefined && rowData.searchStrategists !== null) {
                                                        return rowData.searchStrategists.map(
                                                            strategist => strategist.name.trim()
                                                        ).join(', ');
                                                    }
                                                }
                                            },
                                        ]}
                                        {...(canEdit && {
                                            onRowClick:
                                                ((event, rowData) => {
                                                    let brandId = rowData.id;
                                                    history.push(`${routes.brands.path}/${brandId}`);
                                                })

                                        })}
                                    />
                                </CardContent>
                            </Card>

                        </TabPanel>
                        <TabPanel value="2">
                            <Card variant='outlined'>
                                <CardHeader
                                    title={routes.brands.pageTitle}
                                    subheader='The following brands have been configured for your agency. You can add additional brands or edit brands from this screen.'
                                    action={
                                        <AccessControl allowedPermissions={[permissions.brands.create]}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.addButton}
                                                onClick={
                                                    () => history.push(`${routes.brands.path}/new`)
                                                }
                                            >
                                                Add Brand
                                            </Button>
                                        </AccessControl>
                                    }
                                />
                                <Divider />
                                <CardContent className={classes.cardContent}>
                                    <MaterialUITable
                                        data={showRetailData}
                                        columns={[
                                            { title: 'Brand Name', field: 'brandName' },
                                            { title: 'Retailer Company', field: 'pharmaCompany' },
                                            {
                                                title: 'Search Strategist',
                                                field: 'searchStrategists',
                                                customFilterAndSearch: (term, rowData) => {
                                                    if ( rowData?.searchStrategists !== undefined) {
                                                        return rowData.searchStrategists.some(
                                                            (strategist) => strategist.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
                                                        )
                                                    }
                                                },
                                                render: rowData => {
                                                        if (rowData.searchStrategists !== undefined && rowData.searchStrategists !== null) {
                                                            return rowData.searchStrategists.map(
                                                                strategist => strategist.name.trim()
                                                            ).join(', ');
                                                        }
                                                }
                                            },
                                        ]}

                                        {...(canEdit && {
                                            onRowClick:
                                                ((event, rowData) => {
                                                    let brandId = rowData.id;
                                                    history.push(`${routes.brands.path}/${brandId}`);
                                                })

                                        })}
                                    />
                                </CardContent>
                            </Card></TabPanel>
                    </TabContext>
                    {/* <Card variant='outlined'>
                        <CardHeader
                            title={routes.brands.pageTitle}
                            subheader='The following brands have been configured for your agency. You can add additional brands or edit brands from this screen.'
                            action={
                                <AccessControl allowedPermissions={[permissions.brands.create]}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.addButton}
                                        onClick={
                                            () => history.push(`${routes.brands.path}/new`)
                                        }
                                    >
                                        Add Brand
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.addButton}
                                        onClick={
                                            () => history.push(`${routes.brands.path}/new`)
                                        }
                                    >
                                        Add Retails
                                    </Button>
                                </AccessControl>
                            }
                        />
                        <Divider />
                        <CardContent className={classes.cardContent}>
                            <MaterialUITable
                                data={brands}
                                columns={[
                                    { title: 'Brand Name', field: 'brandName' },
                                    { title: 'Pharma Company', field: 'pharmaCompany' },
                                    {
                                        title: 'Search Strategist',
                                        field: 'searchStrategists',
                                        customFilterAndSearch: (term, rowData) => {
                                            if (rowData.searchStrategists !== undefined) {
                                                return rowData.searchStrategists.some(
                                                    (strategist) => strategist.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
                                                )
                                            }
                                        },
                                        render: rowData => {
                                            if (rowData.searchStrategists !== undefined && rowData.searchStrategists !== null) {
                                                return rowData.searchStrategists.map(
                                                    strategist => strategist.name.trim()
                                                ).join(', ');
                                            }
                                        }
                                    },
                                ]}
                                {...(canEdit && {
                                    onRowClick:
                                        ((event, rowData) => {
                                            let brandId = rowData.id;
                                            history.push(`${routes.brands.path}/${brandId}`);
                                        })

                                })}
                            />
                        </CardContent>
                    </Card> */}
                </Grid>
            </Grid>
        </div>
    );
}